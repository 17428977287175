import request from '../assets/utils/http-request';
import config from '../assets/utils/config';
import { notification } from 'antd';

const lib = {}
const key = 'notification';

const setHeader = () => {
    const user = JSON.parse(localStorage.getItem(config.key.user));
    return user? {
        headers: {
            'Authorization': "Bearer "+user.jwt,
        }
    } : {}
}

lib.verifyPaymentPayStack = async (data) => {
    let header = setHeader();
    if(!Object.keys(header).length) return;

    try {
        let uri = `?verifyPayment`;
        return await (await request.post(uri, {...data, gateway: 'paystack' }, ))
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}

lib.verifyPaymentFlutter = async (data) => {
    let header = setHeader();
    if(!Object.keys(header).length) return;

    try {
        let uri = `?verifyPayment`;
        return await (await request.post(uri, {...data, gateway: 'flutter' }, header))
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}

lib.register = async (data) => {
    try {
        let uri = `?regUser`;
        return await (await request.post(uri, data))
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}

lib.loginSpecial = async (data) => {
    let uri = '';
    try {
        uri = "?" + isDev()? `?login&special` : `?login`;
        return await (await request.post(uri, data))
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}

lib.login = async (data) => {
    let uri = '';
    try {
        uri = `?login`;
        return await (await request.post(uri, data))
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}

lib.update = async () => {
    let header = setHeader();
    if(!Object.keys(header).length) return;

    try {
        let uri = `?clientUpdate`;
        return await (await request.post(uri, {}, header)).data
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}

lib.reset = async (data) => {
    // let header = setHeader();

    // if(!Object.keys(header).length) return;
    
    let uri = '';
    try {
        uri = `?reset`;
        return await (await request.post(uri, data))
    } catch (e) {
        console.log(e);
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}

lib.verify_reset_token = async (data) => {
    // let header = setHeader();

    // if(!Object.keys(header).length) return;
    
    let uri = '';
    try {
        uri = `?verify_reset_token`;
        return await (await request.post(uri, data))
    } catch (e) {
        console.log(e);
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}

lib.saveNewProfile = async (data) => {
    let header = setHeader();
    if(!Object.keys(header).length) return;
    
    let uri = '';
    try {
        uri = `?save-new-profile`;
 
        return await (await request.post(uri, data, header))
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}


// lib.getPrincipal = async (data) => {
//     let uri = '';
//     try {
//         uri = `?getPrincipal`;
 
//         return await (await request.post(uri, data, header)).data
//     } catch (e) {
//         return { status: 'error', msg: e?.response?.data?.msg || e?.message }
//     }
// }


lib.enrollees = async (data) => {
    let header = setHeader();
    if(!Object.keys(header).length) return;
    
    let uri = '';
    try {
        uri = `?enrollees`;
        return await (await request.post(uri, data, header)).data
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}


lib.sendPaymentEvidence = async (data) => {
    let header = setHeader();
    if(!Object.keys(header).length) return;
    
   
    let uri = '';
    try {
        uri = `?uploadPaymentEvidence`;
 
        return await (await request.post(uri, data, header)).data
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}


lib.sendImage = async (data) => {
    let header = setHeader();
    if(!Object.keys(header).length) return;
    
   
    let uri = '';
    try {
        uri = `?upload`;
 
        return await (await request.post(uri, data, header)).data
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}


lib.validatSocial = async (data) => {
    // let header = setHeader();
    // if(!Object.keys(header).length) return;
    
    let uri = '';
    try {
        uri = `?validate_social`;
 
        return await (await request.post(uri, data)).data
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}



lib.saveProfile= async (data) => {
    let header = setHeader();
    if(!Object.keys(header).length) return;
    
    let uri = '';
    try {
        uri = `?save-profile`;
        return await (await request.post(uri, data, header))
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}


lib.getProviders= async (data) => {
    let header = setHeader();
    if(!Object.keys(header).length) return;
    
    let uri = '';
    try {
        uri = `?getProviders`;
        return await (await request.post(uri, data, header)).data
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}


lib.updateBio= async (data) => {
    let header = setHeader();
    if(!Object.keys(header).length) return;
    
    let uri = '';
    try {
        uri = `?update-profile`;
        return await (await request.post(uri, data, header)).data
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}

// Update for users
lib.updateUserData = async (data) => {
    let uri = '';
    try {
        uri = `?new_update`;
        return await (await request.post(uri, data, setHeader()))
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}

// Update for Enrolee for returning policy holders
lib.updateBioDataEnroleeReturn = async (data) => {
    let uri = '';
    try {
        uri = `?new_update_enrolees`;
        return await (await request.post(uri, data, setHeader()))
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}

lib.suggestFacility= async (data) => {
    let header = setHeader();
    if(!Object.keys(header).length) return;
    
    let uri = '';
    try {
        uri = `?saveProvider`;
        return await (await request.post(uri, data, header)).data
    } catch (e) {
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}

export const myRequest = async (type = 'post', uri = 'index', data = {}) => {
    let url = `?${uri}`;
    try {
        if(type === 'get') {
            const resp = await request.get(url, data, setHeader());
            if(resp?.data?.error){
                notification.error({key, message: resp?.data?.message});
                return {error: 1, data: [], message: resp?.data?.message}
            }
            return resp;
        }else {
            const resp = await request.post(url, data, setHeader());
            if(resp?.data?.error){
                notification.error({key, message: resp?.data?.message});
                return {error: 1, data: [], message: resp?.data?.message}
            }
            return resp;
        }
    } catch (e) {
        console.log(e?.response?.data?.msg || e?.message)
        return { status: 'error', msg: e?.response?.data?.msg || e?.message }
    }
}

export const isDev = () =>   !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export function findDifferentKeysTwoObj(obj1, obj2, keysToOmit = []) {
    if (!(obj1 && obj2)) return [];
  
    const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
    const differentKeys = [];
  
    for (const key of allKeys) {
      if (keysToOmit.includes(key)) {
        continue; // Skip keys that are marked for omission
      }
  
      const value1 = obj1[key];
      const value2 = obj2[key];
  
      if (value1 !== value2) {
        differentKeys.push(key);
      }
    }
  
    return differentKeys;
  }

  export function findDifferentKeysTwoObjWithControlKey(obj1, obj2, controlKey, keysToOmit = []) {
    if (!(obj1 && obj2)) return [];
  
    const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
    const differentKeys = [];
  
    for (const key of allKeys) {
      if (keysToOmit.includes(key)) {
        continue; // Skip keys that are marked for omission
      }
  
      const value1 = obj1[key];
      const value2 = obj2[key];
      
      if (value1 !== value2 && value2) {
        const d_keys = {};
        
        value2 && Object.entries(value1).forEach(entry => {
          if(entry[1] !== value2[entry[0]]) d_keys[entry[0]] = entry[1]
        })

        differentKeys.push({new: value1, old: value2, changedKeys: d_keys});
      }
    }
  
    return differentKeys;
  }
  
  export function findDifferentKeysThreeObj(obj1, obj2, obj3, keysToOmit = []) {
    if (!(obj1 && obj2 && obj3)) return [];
    const allKeys = new Set([
      ...Object.keys(obj1),
      ...Object.keys(obj2),
      ...Object.keys(obj3),
    ]);
    const differentKeys = [];
  
    for (const key of allKeys) {
      if (keysToOmit.includes(key)) {
        continue; // Skip keys that are marked for omission
      }
      const value1 = obj1[key];
      const value2 = obj2[key];
      const value3 = obj3[key];
  
      if (value1 !== value2 || value1 !== value3 || value2 !== value3) {
        differentKeys.push(key);
      }
    }
  
    return differentKeys;
  }
  
  export function omitKeys(obj, keysToOmit) {
    if (!obj) return {};
    const newObj = { ...obj };
  
    for (const key of keysToOmit) {
      delete newObj[key];
    }
  
    return newObj;
  }

export default lib;