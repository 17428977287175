

export const IconCheck = ({ style }) => {
    return (
      <svg
        style={{ ...style }}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.2806 7.21937C14.3504 7.28903 14.4057 7.37175 14.4434 7.46279C14.4812 7.55384 14.5006 7.65144 14.5006 7.75C14.5006 7.84856 14.4812 7.94616 14.4434 8.03721C14.4057 8.12825 14.3504 8.21097 14.2806 8.28063L9.03063 13.5306C8.96097 13.6004 8.87825 13.6557 8.78721 13.6934C8.69616 13.7312 8.59856 13.7506 8.5 13.7506C8.40144 13.7506 8.30384 13.7312 8.2128 13.6934C8.12175 13.6557 8.03903 13.6004 7.96938 13.5306L5.71938 11.2806C5.57864 11.1399 5.49958 10.949 5.49958 10.75C5.49958 10.551 5.57864 10.3601 5.71938 10.2194C5.86011 10.0786 6.05098 9.99958 6.25 9.99958C6.44902 9.99958 6.6399 10.0786 6.78063 10.2194L8.5 11.9397L13.2194 7.21937C13.289 7.14964 13.3717 7.09432 13.4628 7.05658C13.5538 7.01884 13.6514 6.99941 13.75 6.99941C13.8486 6.99941 13.9462 7.01884 14.0372 7.05658C14.1283 7.09432 14.211 7.14964 14.2806 7.21937ZM19.75 10C19.75 11.9284 19.1782 13.8134 18.1068 15.4168C17.0355 17.0202 15.5127 18.2699 13.7312 19.0078C11.9496 19.7458 9.98919 19.9389 8.09787 19.5627C6.20656 19.1865 4.46927 18.2579 3.10571 16.8943C1.74215 15.5307 0.81355 13.7934 0.437344 11.9021C0.0611382 10.0108 0.254221 8.05042 0.992175 6.26884C1.73013 4.48726 2.97981 2.96451 4.58319 1.89317C6.18657 0.821828 8.07163 0.25 10 0.25C12.585 0.25273 15.0634 1.28084 16.8913 3.10872C18.7192 4.93661 19.7473 7.41498 19.75 10ZM18.25 10C18.25 8.3683 17.7661 6.77325 16.8596 5.41655C15.9531 4.05984 14.6646 3.00242 13.1571 2.37799C11.6496 1.75357 9.99085 1.59019 8.39051 1.90852C6.79016 2.22685 5.32015 3.01259 4.16637 4.16637C3.01259 5.32015 2.22685 6.79016 1.90852 8.3905C1.59019 9.99085 1.75357 11.6496 2.37799 13.1571C3.00242 14.6646 4.05984 15.9531 5.41655 16.8596C6.77325 17.7661 8.3683 18.25 10 18.25C12.1873 18.2475 14.2843 17.3775 15.8309 15.8309C17.3775 14.2843 18.2475 12.1873 18.25 10Z"
          fill="CurrentColor"
        />
      </svg>
    );
  };

export const IconComponent = {
    // IconBronze: (
    //   <svg
    //     width="24"
    //     height="24"
    //     viewBox="0 0 24 24"
    //     fill="none"
    //     xmlns="http://www.w3.org/2000/svg"
    //   >
    //     <path
    //       d="M20.0484 11.7617L9.54838 23.0117C9.43711 23.1305 9.29023 23.2098 9.12992 23.2377C8.9696 23.2657 8.80455 23.2407 8.65966 23.1667C8.51476 23.0926 8.3979 22.9734 8.3267 22.827C8.2555 22.6807 8.23382 22.5152 8.26494 22.3555L9.63932 15.4808L4.23651 13.452C4.12047 13.4086 4.017 13.3372 3.93533 13.244C3.85366 13.1508 3.79633 13.0389 3.76847 12.9182C3.74062 12.7975 3.7431 12.6718 3.77569 12.5522C3.80828 12.4327 3.86998 12.3231 3.95526 12.2333L14.4553 0.983274C14.5665 0.864526 14.7134 0.785191 14.8737 0.75724C15.034 0.729289 15.1991 0.75424 15.344 0.828326C15.4889 0.902412 15.6057 1.02161 15.6769 1.16794C15.7481 1.31428 15.7698 1.47979 15.7387 1.63952L14.3606 8.52171L19.7634 10.5476C19.8786 10.5913 19.9812 10.6627 20.0622 10.7555C20.1433 10.8483 20.2002 10.9596 20.228 11.0796C20.2558 11.1996 20.2536 11.3246 20.2216 11.4436C20.1897 11.5626 20.1289 11.6718 20.0446 11.7617H20.0484Z"
    //       fill="CurrentColor"
    //     />
    //   </svg>
    // ),
  
    IconPlatinum: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="1" width="43" height="43" rx="21.5" fill="#ebf5fb" />
        <rect
          x="0.5"
          y="1"
          width="43"
          height="43"
          rx="21.5"
          stroke="CurrentColor"
        />
        <path
          d="M33.1914 18.8578L29.7227 29.4825C29.6878 29.5896 29.6292 29.6875 29.5514 29.7688C29.4735 29.8502 29.3783 29.913 29.2728 29.9525C29.1674 29.9921 29.0543 30.0073 28.9422 29.9972C28.83 29.9871 28.7215 29.9518 28.6249 29.8941C28.6033 29.881 26.2061 28.5 21.9986 28.5C17.7911 28.5 15.3939 29.881 15.3705 29.895C15.2738 29.9522 15.1656 29.987 15.0537 29.9968C14.9418 30.0066 14.8291 29.9911 14.724 29.9515C14.6189 29.9119 14.524 29.8492 14.4464 29.768C14.3688 29.6868 14.3104 29.5893 14.2755 29.4825L10.8086 18.855C10.7388 18.6423 10.7334 18.4136 10.7931 18.1978C10.8529 17.982 10.9751 17.7887 11.1445 17.6422C11.3138 17.4957 11.5227 17.4026 11.7449 17.3745C11.967 17.3464 12.1925 17.3846 12.393 17.4844L17.0983 19.8281L21.0283 13.2975C21.1293 13.13 21.2719 12.9914 21.4422 12.8953C21.6126 12.7991 21.8049 12.7485 22.0005 12.7485C22.1961 12.7485 22.3884 12.7991 22.5587 12.8953C22.7291 12.9914 22.8716 13.13 22.9727 13.2975L26.9027 19.8281L31.6099 17.4844C31.8106 17.3845 32.0364 17.3463 32.2588 17.3747C32.4811 17.403 32.6901 17.4966 32.8594 17.6437C33.0286 17.7907 33.1504 17.9846 33.2095 18.2009C33.2686 18.4172 33.2623 18.6461 33.1914 18.8588V18.8578Z"
          fill="CurrentColor"
        />
      </svg>
    ),
  
    IconBronze: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="#ebf5fb" />
        <rect
          x="0.5"
          y="0.5"
          width="43"
          height="43"
          rx="21.5"
          stroke="CurrentColor"
        />
        <path
          d="M30.0484 21.7618L19.5484 33.0118C19.4372 33.1306 19.2903 33.2099 19.13 33.2379C18.9697 33.2658 18.8046 33.2409 18.6597 33.1668C18.5148 33.0927 18.398 32.9735 18.3268 32.8272C18.2556 32.6808 18.2339 32.5153 18.265 32.3556L19.6394 25.4809L14.2366 23.4521C14.1205 23.4087 14.0171 23.3373 13.9354 23.2441C13.8537 23.151 13.7964 23.039 13.7685 22.9183C13.7407 22.7976 13.7432 22.6719 13.7758 22.5524C13.8083 22.4328 13.87 22.3233 13.9553 22.2334L24.4553 10.9834C24.5666 10.8646 24.7135 10.7853 24.8738 10.7574C25.0341 10.7294 25.1992 10.7544 25.344 10.8284C25.4889 10.9025 25.6058 11.0217 25.677 11.1681C25.7482 11.3144 25.7699 11.4799 25.7388 11.6396L24.3606 18.5218L29.7634 20.5478C29.8786 20.5915 29.9813 20.6629 30.0623 20.7556C30.1433 20.8484 30.2003 20.9597 30.2281 21.0797C30.2559 21.1998 30.2537 21.3248 30.2217 21.4437C30.1897 21.5627 30.1289 21.6719 30.0447 21.7618H30.0484Z"
          fill="CurrentColor"
        />
      </svg>
    ),
  
    IconSilver: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="#ebf5fb" />
        <rect
          x="0.5"
          y="0.5"
          width="43"
          height="43"
          rx="21.5"
          stroke="CurrentColor"
        />
        <path
          d="M31.9844 20.7232L27.7563 24.4132L29.0228 29.9069C29.0898 30.1941 29.0707 30.4947 28.9678 30.7711C28.865 31.0475 28.6829 31.2874 28.4444 31.4609C28.2059 31.6344 27.9215 31.7337 27.6269 31.7464C27.3323 31.7592 27.0404 31.6848 26.7878 31.5326L21.9963 28.6263L17.215 31.5326C16.9624 31.6848 16.6706 31.7592 16.3759 31.7464C16.0813 31.7337 15.7969 31.6344 15.5584 31.4609C15.3199 31.2874 15.1379 31.0475 15.035 30.7711C14.9321 30.4947 14.913 30.1941 14.98 29.9069L16.2447 24.4188L12.0156 20.7232C11.792 20.5303 11.6302 20.2756 11.5507 19.9911C11.4712 19.7067 11.4774 19.405 11.5686 19.1241C11.6598 18.8432 11.8319 18.5954 12.0634 18.4119C12.2949 18.2284 12.5754 18.1173 12.8697 18.0926L18.4441 17.6097L20.62 12.4197C20.7336 12.1474 20.9253 11.9148 21.1709 11.7512C21.4164 11.5876 21.7049 11.5002 22 11.5002C22.2951 11.5002 22.5836 11.5876 22.8291 11.7512C23.0747 11.9148 23.2664 12.1474 23.38 12.4197L25.5625 17.6097L31.135 18.0926C31.4294 18.1173 31.7098 18.2284 31.9413 18.4119C32.1728 18.5954 32.3449 18.8432 32.4361 19.1241C32.5273 19.405 32.5335 19.7067 32.454 19.9911C32.3745 20.2756 32.2128 20.5303 31.9891 20.7232H31.9844Z"
          fill="CurrentColor"
        />
      </svg>
    ),
  
    IconGold: (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="#ebf5fb" />
        <rect
          x="0.5"
          y="0.5"
          width="43"
          height="43"
          rx="21.5"
          stroke="CurrentColor"
        />
        <path
          d="M30.625 25.8521L22 30.8818L13.375 25.8521C13.2042 25.7671 13.0075 25.7504 12.8248 25.8053C12.6421 25.8602 12.4872 25.9827 12.3916 26.1478C12.296 26.3129 12.267 26.5082 12.3103 26.694C12.3537 26.8797 12.4662 27.042 12.625 27.1478L21.625 32.3978C21.7397 32.4646 21.87 32.4999 22.0028 32.4999C22.1356 32.4999 22.2659 32.4646 22.3806 32.3978L31.3806 27.1478C31.4671 27.0989 31.5429 27.0333 31.6038 26.9548C31.6647 26.8763 31.7094 26.7866 31.7353 26.6907C31.7612 26.5948 31.7678 26.4947 31.7548 26.3963C31.7417 26.2978 31.7092 26.2029 31.6592 26.1171C31.6091 26.0314 31.5426 25.9564 31.4633 25.8965C31.384 25.8366 31.2937 25.7931 31.1975 25.7685C31.1013 25.7438 31.0011 25.7385 30.9028 25.7529C30.8046 25.7672 30.7101 25.801 30.625 25.8521Z"
          fill="CurrentColor"
        />
        <path
          d="M30.625 21.3521L22 26.3818L13.375 21.3521C13.2042 21.2671 13.0075 21.2504 12.8248 21.3053C12.6421 21.3602 12.4872 21.4827 12.3916 21.6478C12.296 21.8129 12.267 22.0082 12.3103 22.194C12.3537 22.3797 12.4662 22.542 12.625 22.6478L21.625 27.8978C21.7397 27.9646 21.87 27.9999 22.0028 27.9999C22.1356 27.9999 22.2659 27.9646 22.3806 27.8978L31.3806 22.6478C31.4671 22.5989 31.5429 22.5333 31.6038 22.4548C31.6647 22.3763 31.7094 22.2866 31.7353 22.1907C31.7612 22.0948 31.7678 21.9947 31.7548 21.8963C31.7417 21.7978 31.7092 21.7029 31.6592 21.6172C31.6091 21.5314 31.5426 21.4564 31.4633 21.3965C31.384 21.3366 31.2937 21.2931 31.1975 21.2685C31.1013 21.2438 31.0011 21.2385 30.9028 21.2529C30.8046 21.2672 30.7101 21.301 30.625 21.3521Z"
          fill="CurrentColor"
        />
        <path
          d="M12.625 18.1479L21.625 23.3979C21.7397 23.4647 21.87 23.5 22.0028 23.5C22.1356 23.5 22.2659 23.4647 22.3806 23.3979L31.3806 18.1479C31.4938 18.0818 31.5878 17.9872 31.653 17.8736C31.7183 17.7599 31.7527 17.6311 31.7527 17.5C31.7527 17.369 31.7183 17.2402 31.653 17.1265C31.5878 17.0129 31.4938 16.9183 31.3806 16.8522L22.3806 11.6022C22.2659 11.5354 22.1356 11.5001 22.0028 11.5001C21.87 11.5001 21.7397 11.5354 21.625 11.6022L12.625 16.8522C12.5118 16.9183 12.4178 17.0129 12.3526 17.1265C12.2873 17.2402 12.2529 17.369 12.2529 17.5C12.2529 17.6311 12.2873 17.7599 12.3526 17.8736C12.4178 17.9872 12.5118 18.0818 12.625 18.1479Z"
          fill="CurrentColor"
        />
      </svg>
    ),
  
    IconPlatinumPlus: (
      <svg
        width="44"
        height="60" // Increased height to make space for the plus sign above
        viewBox="0 0 44 60" // Updated viewBox to include the plus sign above
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Background Circle */}
        <rect x="0.5" y="15" width="43" height="43" rx="21.5" fill="#ebf5fb" />
        <rect
          x="0.5"
          y="15"
          width="43"
          height="43"
          rx="21.5"
          stroke="CurrentColor"
        />
        
        {/* Star Path */}
        <path
          d="M33.1914 32.8578L29.7227 43.4825C29.6878 43.5896 29.6292 43.6875 29.5514 43.7688C29.4735 43.8502 29.3783 43.913 29.2728 43.9525C29.1674 43.9921 29.0543 44.0073 28.9422 43.9972C28.83 43.9871 28.7215 43.9518 28.6249 43.8941C28.6033 43.881 26.2061 42.5 21.9986 42.5C17.7911 42.5 15.3939 43.881 15.3705 43.895C15.2738 43.9522 15.1656 43.987 15.0537 43.9968C14.9418 44.0066 14.8291 43.9911 14.724 43.9515C14.6189 43.9119 14.524 43.8492 14.4464 43.768C14.3688 43.6868 14.3104 43.5893 14.2755 43.4825L10.8086 32.855C10.7388 32.6423 10.7334 32.4136 10.7931 32.1978C10.8529 31.982 10.9751 31.7887 11.1445 31.6422C11.3138 31.4957 11.5227 31.4026 11.7449 31.3745C11.967 31.3464 12.1925 31.3846 12.393 31.4844L17.0983 33.8281L21.0283 27.2975C21.1293 27.13 21.2719 26.9914 21.4422 26.8953C21.6126 26.7991 21.8049 26.7485 22.0005 26.7485C22.1961 26.7485 22.3884 26.7991 22.5587 26.8953C22.7291 26.9914 22.8716 27.13 22.9727 27.2975L26.9027 33.8281L31.6099 31.4844C31.8106 31.3845 32.0364 31.3463 32.2588 31.3747C32.4811 31.403 32.6901 31.4966 32.8594 31.6437C33.0286 31.7907 33.1504 31.9846 33.2095 32.2009C33.2686 32.4172 33.2623 32.6461 33.1914 32.8588V32.8578Z"
          fill="CurrentColor"
        />
         <circle cx="40" cy="54" r="8" fill="#fff" />
        {/* Plus Sign (Moved Above the Circle) */}
        <path
          d="M37 52 H39 V50 H41 V52 H43 V54 H41 V56 H39 V54 H37 V52 Z"
          fill="CurrentColor"
        />
      </svg>
    )
  };