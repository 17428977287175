import React, { useEffect, useState } from 'react';
import './pricing.scss';
import logo from '../../assets/images/icon/amanhmo.svg';
import { myRequest } from '../lib';
import { notification } from 'antd';
import { IconCheck, IconComponent } from '../../components/svg-icons';
import Loader from '../../components/loader';
import { useNavigate } from 'react-router-dom';

export function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}
export const formattedNumber = (number) => new Intl.NumberFormat().format(number);

const Pricing = () => {
  const navigate = useNavigate();
  // const [segment, setSegment] = useState(window.segment ?? 0)

  // const [plans, setPlans] = useState([])
  const [publicPlans, setPublicPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // if('plansWithSummary' in window) {
    //     setPlans(window.plansWithSummary)
    // }
    setLoading(true);

    myRequest('post', 'orgPlansPublic')
      .then((data) => data?.data)
      .then((data) => {
        if (data?.error) {
          notification.error({ message: data?.message });
        } else {
          const wrangled = data?.data?.map((plan) => ({
            icon:
              IconComponent[`Icon${capitalize(plan.plan_name)?.split(' ').map(e => capitalize(e)).join('')}`] ||
              IconComponent[`${plan.plan_name}Icon`], //{<`Icon${plan.plan_name}` />},
            metal: plan.plan_name,
            name: plan.plan_name,
            title: plan.plan_description,
            mainPrice: formattedNumber(plan.plan_cost),
            price: formattedNumber(plan.plan_six_cost),
            state: {
              option: plan.plan_name,
              price: plan.plan_cost,
              sixPrice: plan.plan_six_cost,
              category: 'self pay',
            },
            benefits: plan.benefits.map((ben) => ben.plan_summary_text),
            linkPath: plan.plan_id,
          }));
          setPublicPlans(wrangled);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  // const loadOrgPlans = () => {
  //     setLoading(true)
  //     setPlans([])
  //     window.plansWithSummary = []
  //     const social_no = orgCode.current.input.value;
  //     myRequest('post', 'validate_org_code', { social_no })
  //     .then(resp => resp.data)
  //     .then(data => {
  //         if (data['error'] ){
  //             notification.error({message: data?.message})
  //         } else {
  //             notification.success({message: data?.message})
  //             myRequest('post', 'orgPlansReg', { id: data?.data?.org_id})
  //             .then(data => data?.data)
  //             .then(data => {
  //                 if(data?.error){
  //                     notification.error({message: data?.message})
  //                 }else{
  //                     const wrangled = data?.data?.map(plan => (
  //                         {
  //                             name: plan.plan_name,
  //                             title: plan.plan_description,
  //                             state: {option: plan.plan_name, price: plan.org_plan_cost, sixPrice: plan.org_plan_familty_cost, social_no, category: 'corporate'},
  //                             benefits: plan.benefits.map(ben => ben.plan_summary_text)
  //                         }
  //                     ))
  //                     console.log(wrangled)
  //                     setPlans(wrangled)
  //                     window.plansWithSummary = wrangled
  //                 }
  //             })
  //             .finally(() => setLoading(false))
  //         }
  //     })
  //     .finally(() => setLoading(false))
  // }

  return (
    <div className="pricing" style={{}}>

      <div className="pricing-container">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px auto' }}>
          <div
            className="pricing-image"
            style={{}}
          >
            <img
              src={logo}
              style={{ width: 300, height: 60, margin: '10px auto' }}
              alt="img"
              onClick={() => window.location.replace('https://www.amanhmo.com')}
            />
          </div>
          <button className='login-btn' onClick={() => navigate('/login')}>Log in</button>
        </div>
        {/* <PageHeaderComp title={<span style={{display: 'flex', alignItems: 'center', justifyItems:'center', justifyContent: 'center'}}><p><BsSquareHalf />&nbsp;Log Me In</p></span>} style={{ fontSize: "16px", color: "#276AFF", cursor: "pointer", marginLeft: "20px", marginTop: "10px", textAlign: "center" }} onClick={() => navigate('/login', { replace: true })} /> */}
        {/* <PackageCards data = {publicPlans} loading={loading}/> */}
        <div style={{ width: '90%', margin: '10px auto', display: "flex", gap: 10, flexDirection: 'row'}}>
          {/* <button className='back-btn'>Back</button> */}
          <h2 style={{ fontWeight: 600, fontSize: '26px', margin: '1.5rem 0' }}>Select Plan</h2>
        </div>
        <PlanContainer data={publicPlans} loading={loading} />
      </div>
    </div>
  );
};

export default Pricing;

export function PlanContainer({ data, handleModal, loading = false }) {
  const navigate = useNavigate();
  // const getStarted = (plan) => {
  //   console.log(plan)
  //   navigate('/register', { state: plan.state })
  // };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', gap: 20 }}>
      {loading && <Loader size={50} />}
      {data?.map((plan, id) => (
        <div className="plan-container" key={id}>
          <div className="header">
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <span className="plan-icon">{plan.icon}</span>
              <span className="plan-metal">{plan.metal}</span>
            </div>
            <div className="price-container">
              &#8358; {plan?.mainPrice}
              <span className="plan-type">{plan.type}</span>
            </div>
          </div>

          <div className="sub-price">
            <span className="amount">&#8358;{plan.price}</span>
            <span className="family-text">Family of Six</span>
          </div>

          <div className="divider"></div>

          <div className="benefits-title">Benefits include:</div>
          {plan?.benefits?.map((val, id) => (
            <div key={id} className="benefit-item">
              <IconCheck />
              <span className="benefit-text">{val}</span>
            </div>
          ))}

          <div className="action-buttons">
            <button
              className="buy-btn"
              onClick={() => navigate('/register', { state: plan.state })}
            >
              Continue
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}
