import React, { useEffect, useRef, useState } from 'react';
import { uri2 } from '../../assets/utils/http-request';
import QRCode from "react-qr-code";
import * as html2canvas from 'html2canvas';
import { Badge, Card, Empty, Modal, notification } from 'antd';
import { useNavigate } from "react-router-dom"
import { PageHeaderComp } from '../../components/pageHeader/pageHeader';
import { InfoCircleOutlined, LogoutOutlined, CreditCardOutlined, ScheduleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ButtonComponent } from '../../components/buttonComponent/buttonComponent';
import './preDashboard.scss';
import { Layout, Divider } from 'antd';
import logom from '../../assets/images/icon/amanhmo.svg';
import { useAuth } from '../../core/hooks/useAuth';
import { useNotifications } from '@mantine/notifications';
import helpers, { hasEmptyEntries } from '../../core/Helpers';
import lib, { findDifferentKeysTwoObjWithControlKey, myRequest } from '../lib';
import ErrorMessage from '../../components/error/ErrorMessage';
import formValidator from '../formvalidation';
import { Select, message, Button } from 'antd';
import { NullSafe } from '../../core/Helpers';
import { UserOutlined } from '@ant-design/icons';
import { Form, Input, Row, Col, DatePicker, Radio } from 'antd';
import UploadImage from './uploadImage';
import UploadDocument from './uploadDocument';
import SuggestFaculty from './suggestFaculty';
import EditBio from './editBio';

// For Paystack payment
import { usePaystackPayment } from 'react-paystack';
import tc1 from '../../assets/images/tc1.png';
import tc2 from '../../assets/images/tc2.png';
import CurrencyFormat from 'react-currency-format';
import { ArrowRightIcon } from '@modulz/radix-icons';
import { gender, maritalStatus, policy_type, states, title } from '../../assets/utils/config';
import moment from 'moment';

const { Content } = Layout;

const { Header } = Layout;

const { TextArea } = Input;

const PreDashboard = () => {
    const [,] = useState('vertical');
    const navigate = useNavigate();
    const { set, user, update } = useAuth();
    const [userCopy, setUserCopy] = useState(user); 

    const [isPrincipalModalVisible, setIsPrincipalModalVisible] = useState(false);
    const [isDependentModalVisible, setIsDependentModalVisible] = useState(false);
    const [, setIsModalVisible] = useState(false);
    const [data, ] = useState(null);
    const [isPaid, setIsPaid] = useState(null);
    const [dependentList, setDependentList] = useState([]);

    const [dependentListCopy, setDependentListCopy] = useState(dependentList);
    const [userAdded, setUserAdded] = useState(false);
    const interValForUpdateBase = useRef();
    const interValForUpdateEnrollees = useRef();

    useEffect(() => {
        if(!userCopy){
            navigate('/login');
        }
    }, [userCopy]) // eslint-disable-line react-hooks/exhaustive-deps

    // Getting principal
    useEffect(() => {
        (async () => {
            if(userAdded){
                let payload = {
                    policy_no: userCopy?.policy_no
                }
                let reqData = await lib.enrollees(payload);
                if (reqData?.error === 1) {

                }
                if (reqData?.error === 0) {
                    let dependent = NullSafe(reqData.data)
                    setDependentList(dependent)
                }
            };
            setUserAdded(false);
        })()
    }, [userAdded]); // eslint-disable-line react-hooks/exhaustive-deps

    // Getting dependent
    useEffect(() => {
        (async () => {
            if(interValForUpdateBase.current) clearInterval(interValForUpdateBase.current);
            if(interValForUpdateEnrollees.current) clearInterval(interValForUpdateEnrollees.current);

            let payload = {
                policy_no: userCopy?.policy_no
            }
            let reqData = await lib.enrollees(payload);
            if (reqData?.error === 0) {
                let dependent = NullSafe(reqData.data)
                setDependentList(dependent)
            }

            interValForUpdateEnrollees.current =  setInterval(async () => {
                if(!userCopy) {
                    clearInterval(interValForUpdateEnrollees.current);
                    return
                } else {
                    upDateDept();
                }
            }, 60000)

            interValForUpdateBase.current =  setInterval(() => {
                if(!userCopy) {
                    clearInterval(interValForUpdateBase.current);
                    return
                } else {
                    upDate()
                }
            }, 20000)

        })()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(Number(userCopy?.status) === 0 && Number(user?.status) === 1){
            notification.success({
                message: <>
                    <strong>Congratulations!</strong><br></br>
                    <span>
                        Your Account Has Been Approved. <br></br>
                        Kindly go ahead and register the biodata of the principal and then dependents.<br></br><br></br>
                        Thank you for choosing Aman HMO.
                    </span>
                </>, duration: 0
            })
        }

        if(Number(userCopy?.status) === 1 && Number(user?.status) === 0){
            notification.error({
                message: <>
                    <strong>Alert!</strong><br></br>
                    <span>
                        Your Account Has Been Temporarily Disabled. <br></br>
                        Kindly get in touch with th customer service for details.<br></br><br></br>
                        Thank you for choosing Aman HMO.
                    </span>
                </>, duration: 0
            })
        }
    }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const changes = findDifferentKeysTwoObjWithControlKey(dependentList, dependentListCopy, 'id');
        changes?.length && changes.forEach(change => {
            if(Number(change?.changedKeys?.status) === 1 && Number(change?.old?.status) === 0) notification.success({
                message: <>
                    <strong>Congratulations!</strong><br></br>
                    <span>
                        <strong>{change?.old?.first_name}</strong> Has Been Approved / Enabled. <br></br>
                        They can now access healthcare.
                    </span>
                </>, duration: 0
            })
            if(change?.changedKeys?.status === 0 && change?.old?.status === 1) notification.error({
                message: <>
                    <strong>Alert!</strong><br></br>
                    <span>
                        <strong>{change?.old?.first_name}</strong> Has Been Temporarily Disabled and cannot access healthcare. <br></br>
                        Kindly get in touch with the customer service for details.
                    </span>
                </>, duration: 0
            })
        });

        setDependentListCopy(dependentList);
    }, [dependentList]); // eslint-disable-line react-hooks/exhaustive-deps

    const upDate = () => {
        lib.update().then(data => {
            if(data.status === 'error') return;
            if(Number(data?.error) === 0){
                const allData = {...userCopy, ...data?.data};
                update(data?.data);
                setUserCopy(allData);
            }
        });
    }

    const upDateDept = () => {
        lib.enrollees({ policy_no: user?.policy_no }).then(resp => {
            if(resp.status === 'error') return;
            if(Number(resp?.error) === 0){
                let dependent = NullSafe(resp?.data)
                setDependentList(dependent)
            }
        });
    }

    const showDependentModal = () => {
        setIsDependentModalVisible(true);
    };
    
    const showPrincipalModal = () => {
        setIsPrincipalModalVisible(true);
    };

    const handleDependentOk = () => {
        setIsDependentModalVisible(false);
    };

    const handleDependentCancel = () => {
        setIsDependentModalVisible(false);
    };

    const handlePrincipalOk = () => {
        setIsPrincipalModalVisible(false);
    };

    const handlePrincipalCancel = () => {
        setIsPrincipalModalVisible(false);
    };

    const onRowSelected = (record) => {
        setIsModalVisible(true);
    };
    
    const logUserOut = () => {
        helpers.logout(set);
        navigate('/login')
        if(interValForUpdateBase.current) clearInterval(interValForUpdateBase.current);
        if(interValForUpdateEnrollees.current) clearInterval(interValForUpdateEnrollees.current);
        setTimeout(window.location.reload(true), 500)
    };

    const verify = () => {
        if(userCopy){
            const status = JSON.parse(localStorage.getItem('paymentStatus'));
            if(status?.policy_no === userCopy.policy_no && status?.isPaid){
                if(!isPaid){
                    setIsPaid(status.isPaid); 
                }
            }
        }
    }

    const setPaymentStatus = () => {
        localStorage.setItem('paymentStatus', JSON.stringify({policy_no: userCopy.policy_no, isPaid: true}));
        setIsPaid(true); 
    }
    
    return (
        <>
            {verify()}
            <Layout style={{ minHeight: '100vh' }}>
                <Header className="header" style={{ backgroundColor: "transparent", display: "flex", justifyContent: "center" }} >
                    <img alt="img" style={{ width: "100px", textAlign: "center"}} src={logom} />
                </Header>

                <Layout style={{}}>
                    <Content>
                        <div className="user-details">
                            {
                                (!Number(user?.status))? 
                                    <div className="user-details-block" style={{backgroundColor: 'rgb(0, 21, 41, 1)', color: '#fff', fontWeight: 'bolder', fontSize: 20}}>
                                        { !parseInt(user?.paymentStatus)? <>   
                                            {parseInt(user?.updated) && !parseInt(user?.update_required) ? <p>
                                                {
                                                    (['self pay', 'referal'].includes(user?.category))? <>
                                                        Status Code {user?.status}{user?.paymentStatus}{user?.updated}{user?.update_required}: 
                                                        Your account is currently not approved, this usually takes less than 24 hours waiting period.<br></br>
                                                        If premium payment has been made through bank or electronic transfer,&nbsp;&nbsp;
                                                        <a href='mailto:info@amanmedicare.org'>info@amanmedicare.org</a> or <a href='tel:info@amanmedicare.org'>08100006975</a> or <br></br> 
                                                        simply &nbsp;&nbsp;&nbsp;<UploadDocument data={user} /><br></br>
                                                        Alternatively, you can &nbsp;&nbsp;&nbsp;<PaystackHookExample user={user} verif={setPaymentStatus} />
                                                        <br></br>
                                                    </> : <>
                                                        Status Code {user?.status}{user?.paymentStatus}{user?.updated}{user?.update_required}:
                                                        Your account is currently undetgoing verification, this usually takes less than 24 hours waiting period.<br></br><br></br>
                                                        If premium payment has been made through bank or electronic transfer, share the payment evidence on 
                                                        <a href='mailto:info@amanhmo.com.'>info@amanhmo.com.</a> or <a href='tel:info@amanhmo.com.'>08100006975</a>
                                                    </>
                                                }
                                                {/* If you feel this is taking too long, please reach out to us through any of the channels above.<br></br> */}
                                                Thank you for choosing us.
                                            </p>  : <p>
                                                Status Code {user?.status}{user?.paymentStatus}{user?.updated}{user?.update_required}: Your account status can not be determined.
                                                Kindly reach out to us through <a href='mailto:info@amanhmo.com.'>info@amanhmo.com.</a> or <a href='tel:info@amanhmo.com.'>08100006975</a> via whatsapp.<br></br>
                                                Thank you for choosing us.
                                            </p>}                                 
                                        </> : <p>
                                            {(parseInt(user?.update_required) && !parseInt(user?.updated))? <>
                                                Status Code {user?.status}{user?.paymentStatus}{user?.updated}{user?.update_required}:
                                                Your account is currently not updated, <stron>kindly update your account using the update button bellow.</stron><br></br>
                                                Should you face any difficulty, kindly reach out to us at <a href='mailto:info@amanhmo.com.'>info@amanhmo.com.</a> or <a href='tel:info@amanhmo.com.'>08100006975</a>.<br></br>
                                                Thank you for choosing us.
                                            </> : <>
                                                {(parseInt(user?.update_required) && parseInt(user?.updated))?<>
                                                    Status Code {user?.status}{user?.paymentStatus}{user?.updated}{user?.update_required}:
                                                    Your account is currently undergoing verification, this usually takes less than 24 hours waiting period after you update your account.<br></br>
                                                    If you feel this is taking too long, please reach out to us at <a href='mailto:info@amanhmo.com.'>info@amanhmo.com.</a> or <a href='tel:info@amanhmo.com.'>08100006975</a>.<br></br>
                                                    Thank you for choosing us.
                                                </> : <>
                                                    Status Code {user?.status}{user?.paymentStatus}{user?.updated}{user?.update_required}: Your account status can not be determined.
                                                    Kindly reach out to us through <a href='mailto:info@amanhmo.com.'>info@amanhmo.com.</a> or <a href='tel:info@amanhmo.com.'>08100006975</a> via whatsapp.<br></br>
                                                    Thank you for choosing us.
                                                </>}
                                            </>}
                                        </p>}
                                    </div> : <>
                                        { 
                                            !parseInt(user?.paymentStatus) && parseInt(user?.updated) && !parseInt(user?.update_required)?   
                                            <div className="user-details-block" style={{backgroundColor: 'rgb(0, 21, 41, 1)', color: '#fff', fontWeight: 'bolder', fontSize: 20}}>
                                                <p>
                                                    Status Code {user?.status}{user?.paymentStatus}{user?.updated}{user?.update_required}:
                                                    Premium Payment is not confirmed.<br></br>
                                                    Kindly reach out to us at info@amanhmo.com. or on 08100006975 via whatsapp.<br></br><br></br>
                                                    Thank you for choosing Aman HMO HMO.
                                                </p>
                                            </div> : !parseInt(user?.paymentStatus) && !parseInt(user?.updated) && parseInt(user?.update_required)? 
                                            <div className="user-details-block" style={{backgroundColor: 'rgb(0, 21, 41, 1)', color: '#fff', fontWeight: 'bolder', fontSize: 20}}>
                                                <p>
                                                    Status Code {user?.status}{user?.paymentStatus}{user?.updated}{user?.update_required}:
                                                    There Are Some Irregularities With Your Account.<br></br>
                                                    Kindly update your account or reach out to us at info@amanhmo.com. or on 08100006975 via whatsapp.<br></br><br></br>
                                                    Thank you for choosing Aman HMO HMO.
                                                </p>
                                            </div> : (!parseInt(user?.paymentStatus) && !parseInt(user?.updated) && !parseInt(user?.update_required)) || 
                                                    (parseInt(user?.paymentStatus) && parseInt(user?.updated) && parseInt(user?.update_required)) || 
                                                    (parseInt(user?.paymentStatus) && !parseInt(user?.updated) && parseInt(user?.update_required)) || 
                                                    (parseInt(user?.paymentStatus) && !parseInt(user?.updated) && !parseInt(user?.update_required)) ? 
                                             <div className="user-details-block" style={{backgroundColor: 'rgb(0, 21, 41, 1)', color: '#fff', fontWeight: 'bolder', fontSize: 20}}>
                                                <p>
                                                    Status Code {user?.status}{user?.paymentStatus}{user?.updated}{user?.update_required}:
                                                    There Are Some Irregularities With Your Account.<br></br>
                                                    Kindly reach out to us at info@amanhmo.com. or on 08100006975 via whatsapp.<br></br><br></br>
                                                    Thank you for choosing Aman HMO HMO.
                                                </p>
                                            </div> : null
                                        }
                                    </>
                            }  
                            <Row gutter={[24, 24]} justify='end'>
                                {(Number(user?.status) && !parseInt(user.update_required) && (dependentList.filter(d => parseInt(d.status) || parseInt(d.updated)).length < parseInt(user?.enrolee_size)) && parseInt(user?.enrolee_size) > 0 ) ?
                                    <Col>
                                        <Button type='primary'  onClick={showDependentModal} icon={<UserOutlined />}>
                                            Add Biodata ({dependentList.filter(d => d?.role?.toLowerCase() === 'principal')?.length  ? <>Enrollee</> : <>Principal</>})
                                        </Button>
                                    </Col>
                                : null}
                                
                                <Col>
                                    <Button type='primary'  onClick={logUserOut} icon={<LogoutOutlined />}>
                                        Logout
                                    </Button>
                                </Col>
                            </Row>

                            <div className="user-details-block">
                                <Row gutter={[12, 12]}>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={8} >
                                        <span style={{ marginRight: "10px", display: 'block'}}><b>Name: </b></span>
                                        <span>{user?.fullName?.toUpperCase()}</span>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={8} >
                                        <span style={{ marginRight: "10px", display: 'block' }}><b>Email: </b></span>
                                        <span>{user?.email?.toUpperCase()}</span>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={8} >
                                        <span style={{ marginRight: "10px", display: 'block' }}><b>Policy Number: </b></span>
                                        <span>{user?.policy_no}</span>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={8} >
                                        <span style={{ marginRight: "10px", display: 'block' }}><b>Insurance Package: </b></span>
                                        <span>{user?.insurance_package?.toUpperCase()}</span>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={8} >
                                        <span style={{ marginRight: "10px", display: 'block' }}><b>Enrollee Size: </b></span>
                                        <span style={{display: 'flex', alignItems: 'center'}}>{dependentList.length} <ArrowRightIcon /> {dependentList.filter(dep => parseInt(dep.status) && parseInt(dep.updated)).length} of {user?.enrolee_size}</span>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={8} >
                                        <span style={{ marginRight: "10px", display: 'block' }}><b>Valid till: </b></span>
                                        <span>{user?.expiry_date}</span>
                                    </Col>
                                </Row>
                            </div>
                            
                            {/* {(parseInt(user?.update_required) && !parseInt(user?.updated))   ? */}
                            {((!Number(user?.status) && !parseInt(user?.paymentStatus) && !parseInt(user?.updated) && parseInt(user?.update_required)) ||
                                (!Number(user?.status) && parseInt(user?.paymentStatus) && !parseInt(user?.updated) && parseInt(user?.update_required))  ||
                                (Number(user?.status) && !parseInt(user?.paymentStatus) && !parseInt(user?.updated) && parseInt(user?.update_required)) )?
                                <Row gutter={[24, 24]} justify='end'>
                                    
                                        <>
                                            <Col>
                                                <Button type='primary'  onClick={showPrincipalModal} icon={<ScheduleOutlined />}>
                                                    Update Account
                                                </Button>
                                            </Col>
                                            <Register user = {user} style={{ top: 10 }} isModalVisible={isPrincipalModalVisible} handleOk={handlePrincipalOk} handleCancel={handlePrincipalCancel} command = {upDate}/>
                                        </>
                                
                                </Row>
                            : null}
                            {
                                (Number(user?.status) && parseInt(user?.paymentStatus) && parseInt(user?.updated) && !parseInt(user?.update_required))? 
                                <ListOfDependent onSelected={onRowSelected} data={data} dependentList={dependentList} user = {user} command = {() => setUserAdded(true)}/> : null
                                // (user?.status && user?.updated)? <ListOfDependent onSelected={onRowSelected} data={data} dependentList={dependentList} user = {user} command = {() => setUserAdded(true)}/> : null
                            }
                        </div>
                    </Content>
                </Layout>
            </Layout>
            <DependantEdit style={{ top: 10 }} isModalVisible={isDependentModalVisible} handleOk={handleDependentOk} handleCancel={handleDependentCancel} biodataList = {dependentList} command = {() => setUserAdded(true)}/>
        </>
    )
}

export default PreDashboard;

const DependantEdit = (props) => {
    const [role, setRole] = useState(props?.biodataList?.some(i => i.role === 'principal')? 'dependent' : 'principal');
    const [,] = useState('hidden');
    const [values, setValues] = useState({role});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const notify = useNotifications();
    const { user } = useAuth();
    const [stateHealthCare, setStateHealtCare] = useState([]);

    const { Option } = Select;

    useEffect(() => {
        error && notification.error({message: error})
    }, [error])


    useEffect(() => {
        setRole(props?.biodataList?.find(i => i.role === 'principal')? 'dependent' : 'principal')
    }, [props.biodataList])

    const selectDate = (date, dateString) => {
        setValues(d => ({ ...d, birth_day: dateString }))
    }

    const fetchProviders = async (e) => {
        setStateHealtCare([])
        let obj = {
            state: e,
            plan_type: user?.insurance_package
        }
        let reqData = await lib.getProviders(obj);

        if (reqData.data?.error === 1) {
            helpers.alert({ notifications: notify, icon: 'error', color: 'red', message: reqData?.data?.message })
        }
        if (reqData?.error === 0) {
            setStateHealtCare(NullSafe(reqData?.data))
            if(!reqData?.data?.length) notification.info({message: <>There are currently no registered active facility in <strong>e?.toLowerCase()</strong></> })
        }
        setLoading(false);
    }

    const handleDependentSubmit = async () => {
        let builder = formValidator.validateDependent({...values, role}, {}, setError)

        if (!builder) {
            setLoading(false);
            return
        }

        setLoading(true);
        builder.policy_no = user.policy_no
        console.log(builder);
        let reqData = await lib.saveProfile(builder);

        if (reqData.data?.error === 1) {
            helpers.alert({ notifications: notify, icon: 'error', color: 'red', message: reqData.data.message })
        }

        if (reqData.data?.error === 0) {
            helpers.alert({ notifications: notify, icon: 'success', color: 'green', message: reqData.data.message })
            // props.handleCancel();
            // props.command();
            setValues({});
        }

        setLoading(false);
    }

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };

    return (
        <>
            <Modal width={1000} open={props.isModalVisible} onOk={props.handleOk} onCancel={props.handleCancel} footer={null} closable destroyOnClose>
                <div style={{ width: "100%", margin: "auto" }}>
                    <PageHeaderComp title={`${props?.biodataList?.find(i => i.role === 'principal')? "Dependent's" : "Principal's"} Biodata`} />
                    <div className="profile-top" >
                        <Row style={{width: '100%'}} gutter={[16, 16]}>
                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16}>
                                    <Col span={12} xs={24} sm={24}>
                                        <Row gutter={16} align="middle">
                                            <Col span={8}>
                                                Insurance package:
                                            </Col>
                                            <Col span={16}>
                                                <Input disabled value={user?.insurance_package} placeholder="Basic, Bronze, Silver ..." style={{ width: "100%" }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}  xs={24} sm={24}>
                                        <Row gutter={16}  align="middle">
                                            <Col span={8}>
                                                Role:
                                            </Col>
                                            <Col span={16}>
                                                <Input value={props?.biodataList?.some(i => i.role === 'principal')? 'dependent' : 'principal'} disabled style={{ width: '100%' }}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={[16,16]}>
                                    <Col span={24} >
                                        <Row gutter={[16, 16]} align="middle">
                                            <Col span={4}>
                                                Title:
                                            </Col>
                                            <Col span={20}>
                                                <Select onChange={e => setValues(d => ({ ...d, title: e }))} value={values?.title} options={title} style={{ width: "100%"}} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12} >
                                        <Row gutter={16} align="middle">
                                            <Col span={8}>
                                                First Name:
                                            </Col>
                                            <Col span={16}>
                                                <Input onChange={e => setValues(d => ({ ...d, first_name: e.target.value }))} value={values?.first_name} placeholder="John" style={{ width: "100%"}} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12} >
                                        <Row gutter={16}  align="middle">
                                            <Col span={8}>
                                                Middle Name:
                                            </Col>
                                            <Col span={16}>
                                                <Input onChange={e => setValues(d => ({ ...d, middle_name: e.target.value }))} value={values?.middle_name} placeholder="Doe" style={{ width: "100%"}} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16}>
                                    <Col span={12} >
                                        <Row gutter={16} align="middle">
                                            <Col span={8}>
                                                Surname:
                                            </Col>
                                            <Col span={16}>
                                                <Input onChange={e => setValues(d => ({ ...d, surname: e.target.value }))} value={values?.surname} placeholder="Clark" style={{ width: "100%"}} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12} >
                                        <Row gutter={16}  align="middle">
                                            <Col span={8}>
                                                Date of Birth:
                                            </Col>
                                            <Col span={16}>
                                                <DatePicker style={{ width: '100%' }} onChange={selectDate} disabledDate={disabledDate}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16}>
                                    <Col span={12} >
                                        <Row gutter={16} align="middle">
                                            <Col span={8}>
                                                Gender:
                                            </Col>
                                            <Col span={16}>
                                                <Radio.Group style={{ marginLeft: '20px' }} options={gender} onChange={e => setValues(d => ({ ...d, gender: e.target.value }))} value={values?.gender} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12} >
                                        {props?.biodataList?.some(i => i.role === 'principal')?
                                            <Row gutter={16}  align="middle">
                                                <Col span={8}>
                                                    Relationship:
                                                </Col>
                                                <Col span={16}>
                                                    <Radio.Group style={{ marginLeft: '20px' }} options={maritalStatus} onChange={e => setValues(d => ({ ...d, relationship: e.target.value }))} value={values?.relationship} />
                                                </Col>
                                            </Row> : null
                                        }
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16}>
                                    <Col span={12} >
                                        <Row gutter={16} align="middle">
                                            <Col span={8}>
                                                Email:
                                            </Col>
                                            <Col span={16}>
                                                <Input onChange={e => setValues(d => ({ ...d, email: e.target.value }))} value={values?.email} placeholder="example@gmail.com" style={{ width: "100%" }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12} >
                                        <Row gutter={16}  align="middle">
                                            <Col span={8}>
                                                Phone number:
                                            </Col>
                                            <Col span={16}>
                                                <Input onChange={e => setValues(d => ({ ...d, phone: e.target.value }))} value={values?.phone} placeholder="09000000000" style={{ width: "100%" }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16}>
                                    <Col span={12} >
                                        <Row gutter={16} align="middle">
                                            <Col span={8}>
                                                State of Residence;
                                            </Col>
                                            <Col span={16}>
                                                <Select 
                                                    showSearch 
                                                    defaultValue={"Select state"} 
                                                    style={{ width: "100%", marginRight: "10px" }} 
                                                    onChange={e => { setValues(d => ({ ...d, state: e })); fetchProviders(e); }} 
                                                    value={values?.state}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => 
                                                        (option?.label ?? '').includes(input) ||
                                                        (option?.value ?? '').includes(input)
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase()) ||
                                                        (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.value ?? '').toLowerCase())
                                                    }
                                                    options={states}
                                                >
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12} >
                                        <Row gutter={16}  align="middle">
                                            <Col span={8}>
                                                City / LGA:
                                            </Col>
                                            <Col span={16}>
                                                <Input onChange={e => setValues(d => ({ ...d, lga: e.target.value }))} value={values?.lga} placeholder="lga" style={{ width: "100%", marginRight: "10px" }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16}>
                                    <Col span={12} >
                                        <Row gutter={16} align="middle">
                                            <Col span={8}>
                                                Religion:
                                            </Col>
                                            <Col span={16}>
                                                <Select defaultValue={values?.religion} style={{ width: '100%' }} onChange={e => setValues(d => ({ ...d, religion: e }))} placeholder="select religion" >
                                                    <Option value="christianity">Christianity</Option>
                                                    <Option value="islam">Islam</Option>
                                                    <Option value="others">Others</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12} >
                                        <Row gutter={16}  align="middle">
                                            <Col span={8}>
                                                Occupatoin:
                                            </Col>
                                            <Col span={16}>
                                                <Input onChange={e => setValues(d => ({ ...d, job_title: e.target.value }))} value={values?.job_title} placeholder="Engineer" style={{ width: "100%"}} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16} align='middle'>
                                    <Col span={4} >
                                        Contact Address:
                                    </Col>
                                    <Col span={20} >
                                        <TextArea onChange={e => setValues(d => ({ ...d, contact_address: e.target.value }))} value={values?.contact_address} showCount style={{  width: "100%" }} rows={3}/>
                                    </Col>
                                </Row>
                            </Col>
                            
                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16}>
                                    <Col span={4}>
                                        Primary Health Care Facility:
                                    </Col>
                                    <Col span={20}>
                                        <Select style={{ width: "100%" }} onChange={e => setValues(d => ({ ...d, primary_health_facility: e }))} value={values?.primary_health_facility}>
                                            {stateHealthCare?.map(item => (
                                                <>
                                                    <Option value={item.facility_name} title={item.facility_name + ' - ' + item.facility_plan_type.toUpperCase()} style={{borderBottom: "1px solid #91caff"}}>
                                                        <span>
                                                            {item.facility_name} <Divider type='vertical' style={{margin: 5, borderColor: '#001d66'}}/> {item.facility_plan_type.toUpperCase()}
                                                        </span>
                                                        <Divider style={{margin: 0}}/>
                                                        <span>
                                                            Address: {item.facility_address}
                                                        </span>
                                                        <Divider style={{margin: 0}}/>
                                                        <span>
                                                            State: {item.facility_state} <Divider type='vertical' style={{margin: 5, borderColor: '#001d66'}}/> City: {item.facility_city.toUpperCase()}
                                                        </span>
                                                    </Option>
                                                </>
                                            ))}
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16}>
                                    <Col span={4}>
                                        Alternate Health Care Facility:
                                    </Col>
                                    <Col span={20}>
                                        <Select style={{ width: "100%", marginRight: "10px" }} onChange={e => setValues(d => ({ ...d, secondary_health_facility: e }))} value={values?.secondary_health_facility}>
                                            {stateHealthCare?.map(item => (
                                                <>
                                                    <Option value={item.facility_name} title={item.facility_name + ' - ' + item.facility_plan_type.toUpperCase()} style={{borderBottom: "1px solid #91caff"}}>
                                                        <span>
                                                            {item.facility_name} <Divider type='vertical' style={{margin: 5, borderColor: '#001d66'}}/> {item.facility_plan_type.toUpperCase()}
                                                        </span>
                                                        <Divider style={{margin: 0}}/>
                                                        <span>
                                                            Address: {item.facility_address}
                                                        </span>
                                                        <Divider style={{margin: 0}}/>
                                                        <span>
                                                            State: {item.facility_state} <Divider type='vertical' style={{margin: 5, borderColor: '#001d66'}}/> City: {item.facility_city.toUpperCase()}
                                                        </span>
                                                    </Option>
                                                </>
                                            ))}
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16}>
                                    <Col span={24} style={{display: 'flex', justifyContent: 'end'}}>
                                        <SuggestFaculty user={user} />
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%', textAlign: 'center'}} gutter={16} justify="center">
                                    <Col span={24}>
                                        <h4>Medical Condition(s)</h4>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16} align="middle" >
                                    <Col span={4}>
                                        Existing Medical Condition:
                                    </Col>
                                    <Col span={20}>
                                        <TextArea onChange={e => setValues(d => ({ ...d, existing_medical_condition: e.target.value }))} value={values?.existing_medical_condition} showCount style={{ width: '100%' }} rows={3} />
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16} align='middle'>
                                    <Col span={4}>
                                        Previous Medical Condition:
                                    </Col>
                                    <Col span={20}>
                                        <TextArea onChange={e => setValues(d => ({ ...d, previous_medical_condition: e.target.value }))} value={values?.previous_medical_condition} showCount style={{ width: '100%' }} rows={3} />
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16} align='middle' justify={'center'}>
                                    <Col span={12} style={{display: 'flex', justifyContent: 'center'}}>
                                        <ButtonComponent style={{padding: '15px auto', width: 'fit-content'}}  content={"Submit"} onClick={handleDependentSubmit} disabled={loading}/>
                                    </Col>
                                    <Col span={12} style={{display: 'flex', justifyContent: 'center'}}>
                                        <ButtonComponent style={{padding: '15px auto', width: 'fit-content', color:'red'}} content="Cancel" onClick={props.handleCancel} disabled={loading} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Modal>
        </>
    )
}

const DependantUpdate = (props) => {
    const fieldsOfInterest = [
        'state', 'title', 'email', 'phone', 'lga', 'existing_medical_condition', 
        'previous_medical_condition', 'policy_no', 'insurance_no', 'primary_health_facility', 'secondary_health_facility',
        'job_title', 'contact_address', 'religion'
    ]

    const [,] = useState('hidden');

    const [values, setValues] = useState(
        Object.keys(props.data)
        .filter((key) => fieldsOfInterest.includes(key))
        .reduce((obj, key) => {
            return Object.assign(obj, {
            [key]: props.data[key]
            });
        }, {})
    );
    
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const notify = useNotifications();
    const { user } = useAuth();
    const [stateHealthCare, setStateHealtCare] = useState([]);
    const [showModal, setShowModal] = useState(false);
    
    const { Option } = Select;

    useEffect(() => {
        if(showModal){
            if(!stateHealthCare.length)
                fetchProviders(values?.state)
        }
    }, [showModal]) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchProviders = async (e) => {
        setStateHealtCare([])
        let obj = {
            state: e,
            plan_type: user?.insurance_package
        }
        let reqData = await lib.getProviders(obj);

        if (reqData.data?.error === 1) {
            helpers.alert({ notifications: notify, icon: 'error', color: 'red', message: reqData.data.message })
        }
        if (reqData?.error === 0) {
            setStateHealtCare(NullSafe(reqData.data))
        }
        setLoading(false);
    }

    const handleDependentSubmit = async () => {
        setLoading(true)
        const d_data = {...values, insurance_package: user?.insurance_package, updated: 1}

        let builder = formValidator.validateDependentEdit(d_data, {}, setError)
        
        if (!builder) {
            notification.error({message: error})
            setLoading(false);
            return
        }

        lib.updateBioDataEnroleeReturn(d_data).then(resp => resp?.data).then(data => {
            if(data?.error){
                notification.error({
                    message: data?.message,
                    duration: 3
                })
            }else {
                notification.success({
                    message: data?.message,
                })
                setShowModal(false);
                props.command();
            }
            setLoading(false)
        })
    }

    const modalVisibleHandler = () => {
        setShowModal(true);
    }

    const handleCancel = () => {
        setShowModal(false);
    }

    const confirm1 = () => {
        Modal.confirm({
            title: <span><strong>Account Update Confirmation</strong></span>,
            icon: <ExclamationCircleOutlined />,
            content: <>
                <p style={{color: 'red'}}><strong>Are you sure you want to submit the form as is?</strong></p>
            </>,
            okText: 'Yes',
            onOk() {handleDependentSubmit()},
            cancelText: 'No',
        });
    };

    return (
        <>
            <Button onClick={modalVisibleHandler}  style={{backgroundColor: "#09A479", border: "none", color: 'white'}}  type="primary" >
                Add Enrollee
            </Button>
            <Modal width={1000} visible={showModal} footer={null} closable destroyOnClose>
                <div style={{ width: "100%", margin: "auto" }}>
                    <PageHeaderComp title="Dependent's Bio Data" />
                    <div className="profile-top" >
                        <Row style={{width: '100%'}} gutter={[16, 16]}>
                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={[16,16]}>
                                    <Col span={24} >
                                        <Row gutter={[16, 16]} align="middle">
                                            <Col span={4}>
                                                Title:
                                            </Col>
                                            <Col span={20}>
                                                <Select onChange={e => setValues(d => ({ ...d, title: e }))} value={values?.title} options={title} style={{ width: "100%"}} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16}>
                                    <Col span={12} >
                                        <Row gutter={16} align="middle">
                                            <Col span={8}>
                                                Email:
                                            </Col>
                                            <Col span={16}>
                                                <Input onChange={e => setValues(d => ({ ...d, email: e.target.value }))} value={values?.email} placeholder="example@gmail.com" style={{ width: "100%" }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12} >
                                        <Row gutter={16}  align="middle">
                                            <Col span={8}>
                                                Phone number:
                                            </Col>
                                            <Col span={16}>
                                                <Input onChange={e => setValues(d => ({ ...d, phone: e.target.value }))} value={values?.phone} placeholder="09000000000" style={{ width: "100%" }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16}>
                                    <Col span={12} >
                                        <Row gutter={16} align="middle">
                                            <Col span={8}>
                                                State of Residence;
                                            </Col>
                                            <Col span={16}>
                                                <Select 
                                                    showSearch 
                                                    defaultValue={"Select state"} 
                                                    style={{ width: "100%", marginRight: "10px" }} 
                                                    onChange={e => { setValues(d => ({ ...d, state: e, primary_health_facility: '', secondary_health_facility: '' })); fetchProviders(e); }} 
                                                    value={values?.state}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => 
                                                        (option?.label ?? '').includes(input) ||
                                                        (option?.value ?? '').includes(input)
                                                    }
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase()) ||
                                                        (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.value ?? '').toLowerCase())
                                                    }
                                                >
                                                    {states?.map(item => (
                                                        <>
                                                            <Option value={item.value}>{item.label}</Option>
                                                        </>
                                                    ))}
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12} >
                                        <Row gutter={16}  align="middle">
                                            <Col span={8}>
                                                City / LGA:
                                            </Col>
                                            <Col span={16}>
                                                <Input onChange={e => setValues(d => ({ ...d, lga: e.target.value }))} value={values?.lga} placeholder="lga" style={{ width: "100%", marginRight: "10px" }} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16}>
                                    <Col span={12} >
                                        <Row gutter={16} align="middle">
                                            <Col span={8}>
                                                Religion:
                                            </Col>
                                            <Col span={16}>
                                                <Select defaultValue={values?.religion} style={{ width: '100%' }} onChange={e => setValues(d => ({ ...d, religion: e }))} placeholder="select religion" >
                                                    <Option value="christianity">Christianity</Option>
                                                    <Option value="islam">Islam</Option>
                                                    <Option value="others">Others</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12} >
                                        <Row gutter={16}  align="middle">
                                            <Col span={8}>
                                                Occupatoin:
                                            </Col>
                                            <Col span={16}>
                                                <Input onChange={e => setValues(d => ({ ...d, job_title: e.target.value }))} value={values?.job_title} placeholder="Engineer" style={{ width: "100%"}} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16} align='middle'>
                                    <Col span={4} >
                                        Contact Address:
                                    </Col>
                                    <Col span={20} >
                                        <TextArea onChange={e => setValues(d => ({ ...d, contact_address: e.target.value }))} value={values?.contact_address} showCount style={{  width: "100%" }} rows={3}/>
                                    </Col>
                                </Row>
                            </Col>
                            
                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16}>
                                    <Col span={4}>
                                        Primary Health Care Facility:
                                    </Col>
                                    <Col span={20}>
                                        <Select style={{ width: "100%" }} onChange={e => setValues(d => ({ ...d, primary_health_facility: e }))} value={values?.primary_health_facility}>
                                            {stateHealthCare?.map(item => (
                                                <>
                                                    <Option value={item.facility_name} title={item.facility_name + ' - ' + item.facility_plan_type.toUpperCase()} style={{borderBottom: "1px solid #91caff"}}>
                                                        <span>
                                                            {item.facility_name} <Divider type='vertical' style={{margin: 5, borderColor: '#001d66'}}/> {item.facility_plan_type.toUpperCase()}
                                                        </span>
                                                        <Divider style={{margin: 0}}/>
                                                        <span>
                                                            Address: {item.facility_address}
                                                        </span>
                                                        <Divider style={{margin: 0}}/>
                                                        <span>
                                                            State: {item.facility_state} <Divider type='vertical' style={{margin: 5, borderColor: '#001d66'}}/> City: {item.facility_city.toUpperCase()}
                                                        </span>
                                                    </Option>
                                                </>
                                            ))}
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16}>
                                    <Col span={4}>
                                        Secondary Health Care Facility:
                                    </Col>
                                    <Col span={20}>
                                        <Select style={{ width: "100%", marginRight: "10px" }} onChange={e => setValues(d => ({ ...d, secondary_health_facility: e }))} value={values?.secondary_health_facility}>
                                            {stateHealthCare?.map(item => (
                                                <>
                                                    <Option value={item.facility_name} title={item.facility_name + ' - ' + item.facility_plan_type.toUpperCase()} style={{borderBottom: "1px solid #91caff"}}>
                                                        <span>
                                                            {item.facility_name} <Divider type='vertical' style={{margin: 5, borderColor: '#001d66'}}/> {item.facility_plan_type.toUpperCase()}
                                                        </span>
                                                        <Divider style={{margin: 0}}/>
                                                        <span>
                                                            Address: {item.facility_address}
                                                        </span>
                                                        <Divider style={{margin: 0}}/>
                                                        <span>
                                                            State: {item.facility_state} <Divider type='vertical' style={{margin: 5, borderColor: '#001d66'}}/> City: {item.facility_city.toUpperCase()}
                                                        </span>
                                                    </Option>
                                                </>
                                            ))}
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16}>
                                    <Col span={24} style={{display: 'flex', justifyContent: 'end'}}>
                                        <SuggestFaculty user={user} />
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%', textAlign: 'center'}} gutter={16} justify="center">
                                    <Col span={24}>
                                        <h4>Medical Condition(s)</h4>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16} align="middle" >
                                    <Col span={4}>
                                        Existing Medical Condition:
                                    </Col>
                                    <Col span={20}>
                                        <TextArea onChange={e => setValues(d => ({ ...d, existing_medical_condition: e.target.value }))} value={values?.existing_medical_condition} showCount style={{ width: '100%' }} rows={3} />
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16} align='middle'>
                                    <Col span={4}>
                                        Previous Medical Condition:
                                    </Col>
                                    <Col span={20}>
                                        <TextArea onChange={e => setValues(d => ({ ...d, previous_medical_condition: e.target.value }))} value={values?.previous_medical_condition} showCount style={{ width: '100%' }} rows={3} />
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24}>
                                <Row style={{width: '100%'}} gutter={16} align='middle' justify={'center'}>
                                    <Col span={12} style={{display: 'flex', justifyContent: 'center'}}>
                                        <ButtonComponent style={{padding: '15px auto', width: 'fit-content' }}  content={"Submit"} onClick={confirm1} disabled={loading}/>
                                    </Col>
                                    <Col span={12} style={{display: 'flex', justifyContent: 'center'}}>
                                        <ButtonComponent style={{padding: '15px auto', width: 'fit-content', color:'red'}} content="Cancel" onClick={handleCancel} disabled={loading} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Modal>
        </>
    )
}

const ListOfDependent = (props) => {
    return (
        <div>
            <div className="site-card-wrapper">
                <Row gutter={[16, 16]} >
                    {
                        props?.dependentList?.length?
                            props?.dependentList.map((item, index) => <DependentCard data = {item}  user = {props.user} command={props.command} key={index}/>):
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<>There No Enrolees For This Account, <br></br>Kindly Use the Add Enrolee Button Above to Onboard Yourself and Your Dependants</>}/>
                    }
                </Row>
            </div>
        </div>
    )
}

const DependentCard = (props) => {
    const item = {...props?.data, update_required: Number(Boolean(props?.data?.update_required)), updated: Number(Boolean(props?.data?.updated)), status: Number(Boolean(props?.data?.status))};
    const imgs = useRef(logom);
    const [downloadIDCARD, setDownloadIDCARD] = useState(false);

    const updateUser = img => imgs.current = img;

    const idCard = () => {
        setDownloadIDCARD(true)
    }

    const downloadCard = (item) => {
        html2canvas(document.querySelector("#id-container_"+item?.insurance_no), { logging: true, letterRendering: 1, allowTaint: false, useCORS: true }).then(canvas => {
            var img   = canvas.toDataURL("image/png");
            const link = document.querySelector('#download_'+item?.insurance_no);
            link.download = `${item.insurance_no}.png`
            link.href = img;
            link.click();
        });
        setDownloadIDCARD(false)
    }

    let styleTitle = {
        color: "#3597d3",
        fontSize: "13px",
        marginRight: "16px",
    }

    let styleDesc = {
        color: "#000",
        fontSize: "13px",
        fontWeight: 'normal',
    }
    
    let imageStyle = {
        width: "150px",
        height: "150px",
        margin: '0 auto',
        marginTop: 10,
        borderRadius: '50%',
    }

    imgs.current = item.pic_name ? `${uri2}${item.pic_name}` : logom

    return (
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <Badge.Ribbon 
                text={
                    (parseInt(item?.status) && parseInt(item?.updated) && !parseInt(item?.update_required))? <>Active</> : 
                    !parseInt(item?.status) && parseInt(item?.updated) && !parseInt(item?.update_required)? <>Disabled: {[item?.status,item?.updated,item?.update_required].join('')}</> : 
                    !parseInt(item?.status) && parseInt(item?.updated) && parseInt(item?.update_required)? <>Submitted: {[item?.status,item?.updated,item?.update_required].join('')}</> : 
                    !parseInt(item?.status) && !parseInt(item?.updated) && parseInt(item?.update_required)? <>Inactive: {[item?.status,item?.updated,item?.update_required].join('')}</> : 
                    <>Error: {[item?.status,item?.updated,item?.update_required].join('')}</>
                } 
                color={
                    (parseInt(item?.status) && parseInt(item?.updated) && !parseInt(item?.update_required))? 'rgb(9, 164, 121)' : 
                    !parseInt(item?.status) && parseInt(item?.updated) && !parseInt(item?.update_required)? 'red' : 
                    !parseInt(item?.status) && parseInt(item?.updated) && parseInt(item?.update_required)? 'skyblue' : 
                    !parseInt(item?.status) && !parseInt(item?.updated) && parseInt(item?.update_required)? 'grey' : "gold"
                }
            >
                <Card
                    hoverable
                    cover={<img style={imageStyle} src={imgs.current} alt=''/>}
                >
                    <Row gutter={[12, 2]}>
                        <Col span={24}>
                            <Row>
                                <Col span={10}>
                                    <b style={styleTitle}>Account Holder:</b>
                                </Col>
                                <Col span={14}>
                                    <span style={styleDesc}>
                                        {Array.from(item.first_name).map((i, j) => j === 0? i.toUpperCase() : i).join('')} &nbsp;
                                        {Array.from(item.surname).map((i, j) => j === 0? i.toUpperCase() : i).join('')}
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row>
                                <Col span={10}>
                                    <b style={styleTitle}>Policy Role:</b>
                                </Col>
                                <Col span={14}>
                                    <span style={styleDesc}> {Array.from(item.role).map((i, j) => j === 0? i.toUpperCase() : i).join('')}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row>
                                <Col span={10}>
                                    <b style={styleTitle}>Insurance ID:</b>
                                </Col>
                                <Col span={14}>
                                    <span style={styleDesc}>{item.insurance_no}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row >
                                <Col span={10}>
                                    <b style={styleTitle}>Primary Health Care:</b>
                                </Col>
                                <Col span={14}>
                                    <span style={styleDesc}>{item.primary_health_facility}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row>
                                <Col span={10}>
                                    <b style={styleTitle}>Secondary Health Care:</b>
                                </Col>
                                <Col span={14}>
                                    <span style={styleDesc}> {item.secondary_health_facility}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row gutter={[12, 12]} justify='center' style={{marginTop: 10}}>
                        {
                            !item.pic_name?
                                <Col xs={8} sm={8} md={8} lg={8} xl={8} > 
                                    {
                                        (parseInt(item?.status) && !parseInt(item?.update_required) && parseInt(item?.updated)) ?
                                        <UploadImage data={item} action = {updateUser} key={item?.id}/> : null
                                    }
                                </Col> : null
                        }
                        

                        <Col xs={8} sm={8} md={8} lg={8} xl={8} > 
                            {
                                parseInt(item?.status) && !parseInt(item?.update_required) && parseInt(item?.updated) ?
                                <EditBio data={item} /> : null
                            }
                        </Col>

                        <Col xs={8} sm={8} md={8} lg={8} xl={8} >
                            {
                                parseInt(item?.status) && !parseInt(item?.update_required) && parseInt(item?.updated) ?
                                    <Button disabled={!item.pic_name} onClick={idCard}  style={{backgroundColor: item.pic_name? "#09A479" : "grey", border: "none", color: 'white'}}  type="primary" >
                                        ID Card
                                    </Button> : null
                            }
                            
                        </Col>

                        {
                            !parseInt(item?.status) && parseInt(item?.update_required) && !parseInt(item?.updated) ?
                                <Col xs={8} sm={8} md={8} lg={8} xl={8} >
                                    <DependantUpdate data={item} command = {props.command}/> 
                                </Col> : null
                        }
                    </Row>

                    <Modal open={downloadIDCARD} onCancel={() => setDownloadIDCARD(false)} okText={'Download'} onOk={() => downloadCard(item)}>
                        <div className={"id-container"} id = {"id-container_"+item?.insurance_no}>
                            <div className="id-container-head">
                                <img src={logom} alt="Aman Logo" />
                            </div>
                            <div className="id-container-qr">
                                <QRCode value={item?.hash}  level='M' size={100}/>
                            </div>
                            <div className="id-container-body">
                                <div className="id-container-body-image">
                                    <img style={imageStyle} src={imgs?.current} alt=''/>
                                </div>
                                <div className="id-container-body-name-div">
                                    <p className="id-container-body-name-div-name">{item?.first_name || ''} {item?.middle_name || ''} {item?.surname || ''}</p>
                                    <Row justify='space-between' className="id-container-body-name-div-name-policy">
                                        <Col span={24}>
                                            <Row>
                                                <Col span={12}><span className="id-container-body-name-div-id">Insurance No.:</span></Col>
                                                <Col span={12}><span style={{color: "#34495e"}}>{item?.insurance_no || ''}</span></Col>
                                            </Row>
                                        </Col>
                                        <Col span={24}>
                                            <Row>
                                                <Col span={12}><span className="id-container-body-name-div-id">Expires:</span></Col>
                                                <Col span={12}><span style={{color: "#34495e"}}>{props.user?.expiry_date.split(' ')[0]}</span></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <a id={'download_'+item?.insurance_no} style={{display: 'none'}} href='home'>Download</a>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </Card>
            </Badge.Ribbon>
        </Col>
    )
}

const PaystackHookExample = ({verif, user}) => {
    const onSuccess = (reference) => {
        const key = 'updatable';
        message.loading({ content: 'Verifying Payment', key});
        const data = {...user, ...reference, gateway: 'paystack'}
        lib.verifyPaymentPayStack(data).then(dat => {
            if(verif) {
                verif();
            }
            message.success({ content: dat.data.message, key, duration: 2});
        })
    };
    
    // you can call this function anything
    const onClose = () => {
        notification.info({
            message: 'Payment Cancelled',
            description: 'Your payment has been cancelled',
            type: 'warning',
            duration: 2,
        })
    }

    const config = {
        reference: (new Date()).getTime().toString(),
        email: user.email,
        amount: Number.parseFloat(user?.policy_cost?.replaceAll(',','')) * 100,
        publicKey: 'pk_live_cf0e7c2a0f6aca730ba9dbfa60d3c0a36d87c1e7',
        // publicKey: 'pk_test_fec9b5f68dcf3265784a6fa20fd8fd7c73a147e2',
    };

    const configHandler = (user, config) => {
        // console.log(user);
        return config
    }

    const initializePayment = usePaystackPayment(configHandler(user, config));

    return (
        // <div className="App">
            <Button
                type='primary'
                size='large'
                onClick={() => {
                    initializePayment(onSuccess, onClose)
                }}
            >
                <CreditCardOutlined />
                Pay Now with Paystack
            </Button>
        // </div>
    );
};

const Register = (props) => {
    const fieldsOfInterest = ['enrolee_size','insurance_package', 'name', 'phone', 'policy_no', 'premium_type', 'email', 'phone', 'category', 'state', 'city'];
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [values, setValues] = useState(
        Object.keys(props.user)
        .filter((key) => fieldsOfInterest.includes(key))
        .reduce((obj, key) => {
            return Object.assign(obj, {
            [key]: props.user[key]
            });
        }, {})
    );

    const [packages, setPlans] = useState([])
    const [prices, setPrices] = useState({})

    const [copyValue, ] = useState(values);

    const [loading, setLoading] = useState(false);
    const [error, ] = useState(false);

    const [addedHeads, setAddedHeads] = useState(
        props.user.premium_type === "family"? 
            Number(props.user.enrolee_size) - 6 > 0 ? Number(props.user.enrolee_size) - 6 : 0
            : Number(props.user.enrolee_size) - 1 > 0 ? Number(props.user.enrolee_size) - 1 : 0 
    );
    
    const [showTermsModal, setShowTermsModal] = useState(false);

    useEffect(() => {
        if(props.isModalVisible){
            // if(props.user.category === 'corporate'){
            //     setLoading(true)
            //     setPlans([])
            //     window.plansWithSummary = []
            //     const social_no = props.user.social_no;
            //     myRequest('post', 'validate_org_code', { social_no })
            //     .then(resp => resp.data)
            //     .then(data => {
            //         if (data['error'] ){
            //             notification.error({message: data?.message})
            //         } else {
            //             myRequest('post', 'orgPlansReg', { id: data?.data?.org_id})
            //             .then(data => data?.data)
            //             .then(data => {
            //                 if(data?.error){
            //                     notification.error({message: data?.message})
            //                 }else{
            //                     setPlans(data?.data?.map(item => ({label: item.plan_name.toUpperCase(), value: item.plan_name.toLowerCase()})))
            //                     const priceItems = {};
            //                     for (const item of data?.data) {
            //                         priceItems[item.plan_name.toUpperCase()] = {price: Number(item.plan_cost), sixPrice: Number(item.plan_six_cost)}
            //                     }
            //                     setPrices(priceItems)
            //                 }
            //             })
            //         }
            //     })
            //     .finally(() => setLoading(false))
            // }else if(props.user.category === 'self pay'){
                setLoading(true)
                myRequest('post', 'orgPlansPublic')
                .then(data => data?.data)
                .then(data => {
                    if(data?.error){
                        notification.error({message: data?.message})
                    }else{
                        setPlans(data?.data?.map(item => ({label: item.plan_name.toUpperCase(), value: item.plan_name.toLowerCase()})))
                        const priceItems = {};
                        console.log(data?.data)
                        for (const item of data?.data) {
                            priceItems[item.plan_name.toUpperCase()] = {price: Number(item.plan_cost), sixPrice: Number(item.plan_six_cost)}
                        }
                        setPrices(priceItems)
                    }
                }).finally(() => setLoading(false))
        //     }
        }
    }, [props.isModalVisible]) // eslint-disable-line react-hooks/exhaustive-deps

    const confirm = () => {
        Modal.confirm({
            title: <span><strong>Account Update Summary</strong></span>,
            icon: <ExclamationCircleOutlined />,
            content: <>
                <Row>
                    {
                        Object.keys(values).map(key => {
                            return (
                                <Col span={24} style={{margin: '2px 0', padding: '5px 0'}}>
                                    <Row justify='space-between'>
                                        <Col span={12}>{key.split('_').join(' ').toLocaleUpperCase()}:</Col>
                                        <Col span={12}>{key === 'policy_cost'? <CurrencyFormat value={values[key]} displayType={'text'} thousandSeparator={true} prefix={'₦'} /> : values[key] }</Col>
                                    </Row>
                                </Col>
                            )
                        })
                    }
                </Row>
                <Divider style={{padding: 0, margin: 0}}/>
                <p style={{color: 'red'}}><strong>Are you sure all the data entered are correct and you want to go ahead with the submission?</strong></p>
            </>,
            okText: 'Yes',
            onOk: () => setShowTermsModal(true),
            cancelText: 'No',
        });
    };

    const confirm1 = () => {
        Modal.confirm({
            title: <span><strong>Account Update Summary</strong></span>,
            icon: <ExclamationCircleOutlined />,
            content: <>
                <Row>
                    {
                        Object.keys(values).map(key => {
                            return (
                                <Col span={24} style={{margin: '2px 0', padding: '5px 0'}}>
                                    <Row justify='space-around'>
                                        <Col span={12}>{key.split('_').join(' ').toLocaleUpperCase()}:</Col>
                                        <Col span={12}>{key === 'policy_cost'? <CurrencyFormat value={values[key]} displayType={'text'} thousandSeparator={true} prefix={'₦'} /> : values[key] }</Col>
                                    </Row>
                                </Col>
                            )
                        })
                    }
                </Row>
                <Divider style={{padding: 0, margin: 0}}/>
                <p style={{color: 'gold'}}><strong>You have not make any changes to this form data, are you sure you want to submit the form as is?</strong></p>
            </>,
            okText: 'Yes',
            onOk() {setShowTermsModal(true)},
            cancelText: 'No',
        });
    };

    const handleOk = () => {
        setLoading(true);
        const data =  { ...values, updated: 1 };
        lib.updateUserData(data).then(res => res.data).then(data => {
            if(!data?.error){
                notification.success({
                    message: data?.message
                })
                props.handleCancel();
                props.command();
            }
        }).catch(err => {
            setLoading(false);
        })

        return 1
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const checkData = () => {
        const updated = JSON.stringify(values) === JSON.stringify(copyValue);
        console.log(values)
        if(hasEmptyEntries(values)){
            Modal.error({
                title: 'Empty Data Field',
                icon: <ExclamationCircleOutlined />,
                content: <>All Fields must be properly filled</>,
                okText: 'Ok'
            });
            return;
        }
        
        switch(values['premium_type']){
            case 'family':
                values['enrolee_size'] = 6 + Number(addedHeads);
                break;
            case 'individual':
                values['enrolee_size'] = 1 + Number(addedHeads);
                break;
            default: break;
        }

        values['policy_cost'] = values['premium_type'] === 'family'?  
            prices[values['insurance_package'].toUpperCase()].sixPrice + 
            (Number(values['enrolee_size']) - 6) * prices[values['insurance_package'].toUpperCase()].price : 
            Number(values['enrolee_size']) * prices[values['insurance_package'].toUpperCase()].price 

        if(updated){
            confirm1();
            return;
        }

        confirm();
    }

    return (
        <>
            <Modal open={props.isModalVisible}
                footer = {<>
                    <Row justify='end'>
                        <Col>
                            <Button type={'primary'} danger onClick={props.handleCancel}>Close</Button>
                        </Col>
                        <Col>
                            <Button type={'primary'} onClick={checkData} disabled={loading}>Update</Button>
                        </Col>
                    </Row>
                </>}
            >
                <Form form={form} layout="vertical">
                    <Form.Item label="Insurance Package" required tooltip="Select Insurance Package" style={{ width: "100%" }}>
                        <Select value={values?.insurance_package.toUpperCase()} onChange={e => setValues(d => ({...d, insurance_package: e}))} options={packages} style={{width: '100%'}} />
                    </Form.Item>
                    <Form.Item label="Premium Type" required tooltip="Select premium type" style={{ width: "100%" }}>
                        <Select defaultValue="Select premium type" onChange={e => setValues(d => ({ ...d, premium_type: e }))} value={values?.premium_type} style={{ width: "100%", marginRight: "10px" }} options={policy_type} />
                    </Form.Item>
                    <Form.Item label="Full Name" required tooltip="Enter your Full name" style={{ width: "100%" }}>
                        <Input onChange={e => setValues(d => ({ ...d, name: e.target.value }))} autoFocus value={values?.name || values?.fullName} placeholder="Musa Umar" style={{ width: "100%", marginRight: "10px", borderColor: values.name === ''? 'red' : null}} />
                    </Form.Item>
                    <Form.Item 
                        label={<span>Add additional dependents <br></br><span style={{color: 'brown', fontSize: 15, fontWeight: 100}}>Use this if you have more than the enrolees specifid by your package type i.e <strong>Family: 6 enrolees, Individual: 1 enrolee,</strong> principal included.</span></span>} 
                        style={{ width: "100%" }}
                    >
                        <Select defaultValue="0" style={{ width: "100%", marginRight: "10px" }} onChange={e => setAddedHeads(e)} value={addedHeads}  options={ Array.from({ length: 11 }).map((item, index) => ( { label: index, value: index }) ) } />
                    </Form.Item>
                    <Form.Item label="State of Residence" required tooltip={{ title: 'Enter your state', icon: <InfoCircleOutlined /> }} style={{ width: "100%" }}>
                        <Select defaultValue={"Select state"} style={{ width: "100%", marginRight: "10px" }} onChange={e => { setValues(d => ({ ...d, state: e }));}} value={values?.state} options={states} /> 
                    </Form.Item>
                    <Form.Item label="City" required tooltip="Enter your City" style={{ width: "100%" }}>
                        <Input onChange={e => setValues(d => ({ ...d, city: e.target.value }))} value={values?.city} placeholder="Ogidilu" style={{ width: "100%", marginRight: "10px", borderColor: values.city === ''? 'red' : null }} />
                    </Form.Item>
                    { error ? <ErrorMessage message={error} /> : null }
                    <ModalContent isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} />
                </Form>
            </Modal>
            <ModalContent handleOk={!loading? handleOk : null} handleCancel={() => setShowTermsModal(false)} isModalVisible={showTermsModal} loading={loading}/>
        </>
    )
}

const ModalContent = (props) => {

    return (
        <Modal width={800}
            style={{ top: 20, textAlign: 'justify' }}
            title={<strong>Terms and Conditions</strong>} open={props.isModalVisible} onOk={props.handleOk} onCancel={props.handleCancel} okText={'Agree'}>
            <div className='terms' >
                <h2>Section I - GENERAL PROVISIONS</h2>
                
                <h4>1. Enrollee, membership, coverable persons and eligibility for cover</h4>
                <p style={{paddingLeft: '.5em'}}>
                    <ul>
                        <strong>1.1.</strong> By subscribing to any of Aman HMO plans, enrollees automatically consent to 
                        become associate members of Aman HMO. Associate members are the 
                        collective owners of the pool of contributions (premiums) from which medical 
                        benefits are paid, but they do not possess voting rights.
                    </ul>
                    <ul>
                        <strong>1.2.</strong> Only members who have fully paid up their contributions shall be entitled to health cover.
                    </ul>
                    <ul>
                        <strong>1.3.</strong> The enrollee is the natural or legal person who has subscribed to any
                        of Aman HMO’s health plans. The coverable persons are the enrollees and their dependents. 
                        Newborn infants of covered persons shall be included in the cover after birth, 
                        on the same plan as their parents. This is subject to the following conditions:
                        <ul>
                            <li>they are enrolled with Aman HMO within 2 months of the day of birth with retrospective effect,</li>
                            <li>the health cover contract was concluded at least 3 months earlier without interruption and</li>
                            <li>no other health cover exists.</li>
                        </ul>
                        
                    </ul>
                    <ul>
                        <strong>1.4.</strong> The following persons are not eligible and will not be given cover, 
                        even if payment of contributions is made
                    </ul>
                    <ul>
                        <strong>1.5.</strong> Persons permanently in need of care as well as persons whose participation
                        in everyday life is permanently excluded. The mental condition and objective living conditions 
                        in particular of said persons shall be taken into account as regards classification. Persons in 
                        need of care are those persons who largely require external assistance to complete everyday tasks;
                    </ul>
                    <ul>
                        <strong>1.6.</strong> The health cover contract cannot be signed for persons who do not fulfill 
                        the requirements of Clauses 1.1 and 1.2, even if the contribution is paid. If, however, 
                        the contribution is paid for these persons, a refund is available to the person paying the contribution.
                    </ul>
                </p>

                <br></br>
                <h3>2. Taking out cover, commencement, duration and termination of the policy and health cover</h3>
                <p style={{paddingLeft: '.5em'}}>
                    <h4>
                        <strong>2.1. Enrollment and commencement of the cover</strong>
                    </h4>
                    <ul><strong>2.1.1. </strong>Health cover plans can be purchased at any time.</ul>
                    <ul>
                        <strong>2.1.2. </strong>The health cover contract is concluded when Aman 
                        Medicare has received the correctly completed application 
                        form (either electronically or physically) for this and has 
                        sent you confirmation of cover. The application is only considered 
                        correctly completed when it contains all the requested information 
                        in an unambiguous and complete form.
                    </ul>
                    <ul>
                        <strong>2.1.3. </strong>If clauses 2.1.1 or 2.1.2 are not met, the cover is not valid even 
                        if the contribution is paid. In this case, the person paying the 
                        contribution is entitled to a refund.
                    </ul>        
                    <h4>
                        <strong>2.2. Commencement of Healthcare Cover</strong>
                    </h4>
                    <ul>
                        <strong><u>Waiting Period:</u></strong> means the period of time commencing on the date of 
                        commencement of the cover during which an enrollee is required to wait to 
                        be entitled to access care under plans. The length of this period is 14 days. 
                        Therefore, a cover purchased becomes active 14 days after the completion of 
                        registration.
                    </ul>  
                    <h4><strong>2.3. Duration</strong></h4>
                    <ul>
                        All plans are quoted for a period of one year, and can be renewed annually.
                    </ul>
                    <h4><strong>2.4. Termination</strong></h4>
                    <ul>
                        The statutory provisions concerning the right to termination for cause remain 
                        unaffected by these agreements. The health care cover ends upon termination 
                        of the contractual cover. The contractual cover also ends for covered events 
                        not yet concluded or pending:
                    </ul>
                    <ul>
                        <strong>2.4.1. </strong>At the agreed time
                    </ul>
                    <ul>
                        <strong>2.4.2. </strong>With the death of the enrollee; the covered persons may extend the 
                        health cover policy within 2 months of the enrollee’s death by 
                        nominating a successor health cover beneficiary
                    </ul>
                    <ul>
                        <strong>2.4.3. </strong>If the eligibility criteria are no longer met
                    </ul>
                    <ul>
                        <strong>2.4.4. </strong>Aman HMO is at liberty to cancel the cover of any covered member(s) 
                        who has/have misled it or breached any term of this agreement, 
                        giving incorrect, incomplete or misleading information, 
                        failed to provide any reasonable information which Aman HMO requested, 
                        conspired with a third party to obtain undue benefit from this policy, 
                        or submitted a claim in which is clearly fraudulent or unfounded. 
                        In any of these circumstances, Aman HMO has the right to cancel the members 
                        cover from the date of commencement (without refund of any portion of unused contribution) 
                        and recover from him/her any benefit it might have paid as a result of such claim.
                    </ul>
                    <ul>
                        <strong>2.4.5. </strong>Enrollees making annual payments can cancel their policy within the first 30 days of the 
                        commencement of the policy. An enrollee that decides to cancel or terminate his 
                        policy/cover must notify Aman HMO in writing, and the enrollee shall be 
                        entitled to a refund of contribution paid less (a) any amounts incurred on 
                        their behalf as medical and other expenses. (b). An administrative 
                        (Wakala fee) of 25% of the contribution paid. No refund shall apply in 
                        the case of termination/cancellation made by enrollee after 30 days of the commencement of policy.
                    </ul>
                    <ul>
                        <strong>2.4.6. </strong>In the rare circumstance where Aman HMO has to terminate the policy, 
                        it shall notify the enrollee in writing to their last known address or 
                        electronically via the last known email address. In such an event, 
                        Aman HMO will refund to the enrollee an amount equal to pro-rata 
                        value of their unused contribution.
                    </ul>
                    <h4><strong>2.5. Health cover Year</strong></h4>
                    <ul>
                        <strong>2.5.1.</strong> A health cover year is considered to be a period of 12 months. 
                        The first health cover year commences on the date the health cover policy begins. 
                        If a benefit which is limited per health cover year is claimed in a year, 
                        the health cover for this benefit (once the benefit limit is reached) 
                        is restored only after this health cover year elapses. 
                        If a benefit is limited per covered year, health cover for this benefit 
                        continues until the benefit limit is reached, even if the contract duration 
                        is less than 12 months.
                    </ul>
                </p>

                <br></br>
                <h3>3. Scope of the Cover</h3>
                <p style={{paddingLeft: '.5em'}}>
                    <ul>
                        <strong>3.1. </strong>The health cover applies only to healthcare providers within Nigeria.
                    </ul>
                </p>
                <br></br>

                <h3>4. What requirements must be complied with when paying the Contributions?</h3>
                <p style={{paddingLeft: '.5em'}}>
                    <h4><strong>4.1.</strong> Contribution amount</h4>
                    <ul>
                        The contribution for a covered person is as contained in the plans, 
                        available to the enrollee(s) at point of enrollment.
                    </ul>

                    <h4><strong>4.2.</strong> Payment of the first or one-off contribution</h4>
                    <ul>
                        <strong>4.2.1.</strong>	The first or one-time contribution is due at the start 
                        of the contract/relationship/membership.
                    </ul>
                    <ul>
                        <strong>4.2.3.</strong> In addition, Aman HMO will be entitled to 
                        terminate the contract so long as the contribution remains unpaid.
                    </ul>
                    <h4>4.3. Payment of subsequent contribution</h4>
                    <ul>
                        <strong>4.3.1 </strong>If the subsequent contribution is not paid on time, 
                        Aman HMO will send you a reminder and will set a time limit of 2 weeks.
                    </ul>
                    <ul>
                        <strong>4.3.2 </strong>If you have still not made the payment when this deadline expires, 
                        Aman HMO is entitled to terminate the contract, 
                        if it has drawn your attention to this when the reminder was sent.
                    </ul>

                    <h4>4.4. Collection of contributions</h4>
                    <ul>
                        <strong>4.4.1</strong> If you have agreed to the contribution being collected from your account by direct debit,
                            this will take place as soon as the mandate has been set up. 
                            The payment is considered to have been made in a timely manner 
                            if Aman HMO can collect the contribution on the due  date, 
                            and you do not dispute collection of the correct payment.
                    </ul>
                    <ul>
                        <strong>4.4.2</strong> If Aman HMO is unable to collect the contribution due for a 
                        reason beyond your control, the payment shall still be considered to be on time 
                        if payment is made immediately upon receipt of the written reminder from Aman HMO.
                    </ul>
                    <ul>
                        <strong>4.4.3</strong> Contributions are due annually and the full annual amount is established 
                        at the start of annual cover or a renewal period thereof.
                    </ul>

                    <h4>4.5. Refunds</h4>
                    <ul>
                        Enrollees are required to access medically necessary care and services within 
                        Aman HMO's plan covers and network of Providers alone and not pay out 
                        of pocket for covered services. In the odd event that an enrollee has to pay out of pocket, 
                        this only has to be in an emergency situation as qualified by a medical practitioner 
                        without influence of the enrollee. The refund will be made by Aman HMO upon the 
                        enrollee providing the following documents within 30 days of encounter: 
                        (1) copy of medical report from the Health care practitioner indicating history of 
                        the medical condition, diagnosis and treatment administered, (2) Original Receipt for 
                        having made payment indicating the costs separately for consultation, each investigation, 
                        each procedure and each Drug and the quantity dispensed.
                    </ul>
                    <ul>
                        All refunds will be made only into the enrollees designated account based only on medical 
                        necessity  as  might  be  reviewed  by  Aman HMO's  in-house  medical practitioners 
                        and only at Aman HMO's designated/customary rate irrespective of the cost of care. 
                        Also, refunds are only applicable where the same is received before receipt by 
                        Aman HMO of the notice to terminate or expiration of a plan.
                    </ul>
                </p>

                <br></br>
                <h3>5. Categorization of Healthcare Facilities</h3>
                <p style={{paddingLeft: '.5em'}}>
                    Healthcare Providers are categorized by Aman HMO for the benefit of ease of access 
                    to care by its Enrollees. Aman HMO reserves the right to review this categorization 
                    from time to time without prior recourse to an Enrollee. This could include (but not limited to) 
                    the addition and deletion of healthcare providers from the general list and/or from a specific 
                    plan provider list. An Enrollee will however always have access to a number of healthcare 
                    facilities within their applicable Aman HMO network of Providers.
                </p>
                <br></br>

                <h2>Section II - DESCRIPTION OF BENEFITS</h2>

                <h3>1. Scope of health cover</h3>
                <p style={{paddingLeft: '.5em'}}>
                    <ul>
                        <strong>1.2. </strong>Medically necessary treatment of an enrollee due to illness or 
                        accident is considered to be a covered event. The covered event starts with your treatment. 
                        It ends once it is medically established that no further treatment is needed. 
                        If the treatment needs to be extended to an illness or consequences of an accident 
                        that is not causally linked to treatment up to that point, a new covered event shall 
                        be considered to have occurred. A covered event is also deemed to be the death of the 
                        enrollee, examinations and medically necessary treatments for complaints during 
                        pregnancy and delivery, vaccinations as well as outpatient check-ups.
                    </ul>
                    <ul>
                        <strong>1.2. </strong>During your stay, you have free choice of the doctors, 
                        dentists and hospitals recognised and accredited in the country of destination. 
                        Hospitals must be under permanent medical management. They must have sufficient 
                        diagnostic and therapeutic facilities and manage case histories. 
                        For treatments in sanatoriums that also carry out cures or sanatorium treatments, 
                        our written confirmation must first be obtained. Aman HMO reimburses the costs 
                        arising in accordance with clause 2 (Covered benefits).
                    </ul>
                    <ul>
                        <strong>1.3. </strong>Aman HMO only pays for diagnostic and treatment methods   
                         and medications that are universally or generally recognised by conventional medicine.
                    </ul>
                    <ul>
                        <strong>Drugs and Medications:</strong> all enrollees are covered for drugs recommended in the course of 
                        their treatment for covered services as defined in the plan benefit except for excluded items. 
                        As a standard, enrollees shall be prescribed generic drugs. except where no generic option exists, 
                        in which case, prior approval will be sought by the provider to dispense branded medication.
                    </ul>
                    <ul>
                        In the event that the provider or enrollee prefers a branded option where a generic option is 
                        available, the differential of such option may be paid for directly by the enrollee to the provider. 
                        Where the provider does not maintain a stock of generic medications, 
                        a prescription should be collected and the generic medication taken from a pharmacy 
                        within Aman HMO’s registered service providers. Where the medication is not available 
                        within the network of registered pharmacies, a pre-approval of Aman HMO is necessary 
                        for the transaction to qualify for refund in line with Section I (4.4) above.
                    </ul>
                    <ul>
                        In the event that the provider or enrollee prefers a branded option where a generic option 
                        is available, such option may be paid for directly by the enrollee to the provider. 
                        Kindly note that where the provider does not maintain a stock of generic medications, 
                        a prescription should be collected and the generic medication taken from a pharmacy for 
                        which Aman HMO will be responsible.
                    </ul>
                </p>
                <br></br>

                <h3>2. Restrictions to Health care Cover</h3>
                <p style={{paddingLeft: '.5em'}}>
                    <h4><strong>2.1. Exclusions of cover</strong></h4>
                    Aman HMO does not pay out:
                    <ul>
                        <strong>2.1.1 </strong>If you have wilfully brought about the covered event or attempt to make fraudulent 
                        representations to us as to the circumstances which are material to the grounds for 
                        providing cover and the amount of covered benefits.
                    </ul>
                    <ul>
                        <strong>2.1.2 </strong>Aman HMO will not cover or pay for any treatment 
                        that was given before an Enrollee's commencement date of cover (including waiting periods) 
                        or after cancellation/termination of cover or during any period for which Aman HMO 
                        is yet to receive premiums.
                    </ul>
                    <ul>
                        <strong>2.1.3 </strong>Aman will not cover or pay for any treatment that is not specifically 
                        covered under the Benefit Schedule of the Policy. Aman HMO will not cover nor pay for 
                        other conditions or procedures which are not specified as covered services in the schedule 
                        of benefits for the plans. Similarly,   the   plans   do   not   cover   self-medication   
                        or   Consultations   with unrecognized/un-orthodox consultants, hospitals, family doctors, 
                        therapists, dental practitioners  or  complementary  medicines   practitioners.  
                        In  the  same  vein, complications from such unrecognized/un-orthodox places are 
                        not covered under the plans.
                    </ul>
                </p>
                <br></br>
                
                <h3>3. Transferability of Cover</h3>
                <p style={{paddingLeft: '.5em'}}>
                    All Aman HMO cover plans are person specific and non-transferable.
                </p>
                <br></br>
                
                <h3>4. Confidentiality</h3>
                <p style={{paddingLeft: '.5em'}}>
                    Aman HMO is under contractual and medical obligation to protect the information of its 
                    members and it is bound by law and regulatory standards to maintain the privacy of its 
                    enrollees’ medical information and records. Aman HMO also holds its employees, 
                    providers, and consultants to strict policies and procedures in protecting members/enrollees 
                    information and medical records. The information collected from an enrollee at enrollment must 
                    be adequately protected and preserved.
                </p>
                 <br></br>
                
                <h3>5. Complaint Resolution</h3>
                <p style={{paddingLeft: '.5em'}}>
                    <img src={tc1} style={{margin: '0 auto', display: 'block'}} alt=''></img>
                    <br></br>
                    <img src={tc2} style={{margin: '0 auto', display: 'block'}} alt=''></img>
                </p>
                <br></br>

                <h2>Section III - TAKAFUL PRINCIPLES AND CONDITIONS</h2>
                <p style={{paddingLeft: '.5em'}}>
                    <ul>
                        <strong>1.</strong> The principles of takaful and provisions of Shariah shall be applicable 
                        to this policy as guided by AAOIFI Shariah Standards and the advice of qualified local 
                        scholars.
                    </ul>
                    <ul>
                        <strong>2.</strong> Aman HMO shall expend the cost of medical care given to the enrollees out of the 
                        contribution pool (takaful funds) according to the terms and conditions set forth in this 
                        policy and the scope defined by the policyholder’s plan.
                    </ul>
                    <ul>
                        <strong>3.</strong> An enrollee shall donate all or part of his contribution to pay for the medical 
                        expenses of any of the other enrollees as per the cooperative or takaful principles.
                    </ul>
                    <ul>
                        <strong>4.</strong> Aman HMO shall manage the takaful operation for the benefit of the enrollees as 
                        Wakil (agent) and will charge a maximum annual administrative fee of 25% based on the principle 
                        of Wakala (agency) on the Gross Annual Contribution.
                    </ul>
                    <ul>
                        <strong>5.</strong> Aman HMO shall invest the takaful fund for the collective benefit of 
                        enrollees on a Mudaraba basis for a fee equal to a percentage of the realized profits. 
                        It will be entitled to 50% share of the net investment profit (if any) as Mudarib fee 
                        for its management of the investment portfolio which will be calculated at the end of 
                        each investment cycle.
                    </ul>
                    <ul>
                        <strong>6.</strong> The Wakala fee and Mudarib fee percentages will be announced in advance 
                        before the beginning of the financial year and will be mentioned in every policy or in 
                        renewal notices.
                    </ul>
                    <ul>
                        <strong>7.</strong> Aman HMO shall distribute from the underwriting surplus to 
                        eligible enrollees as follows:
                        <ul>
                            <strong>a.</strong> An enrollee will not have the right to receive any share of the 
                            underwriting surplus, if he/she received medical care where the cost is equivalent 
                            to 50% or more of his/her contribution.
                        </ul>
                        <ul>
                            <strong>b.</strong> An enrollee will have the right to receive a proportionate share 
                            of the underwriting surplus (after deducting the cost of medical care) if the cost of 
                            medical benefit enjoyed is less than 50% of the contributions.
                        </ul>
                        <ul>
                            <strong>c.</strong> For the purpose of (a) above, an enrollee’s financial position 
                            shall be deemed as one position (aggregated) in the event of holding multiple 
                            types of health plans with Aman HMO.
                        </ul>
                    </ul>
                    <ul>
                        <strong>8.</strong> If the enrollee does not collect his/her share of surplus under (7) 
                        above, within a period of 12 months from the end of the financial year during which his 
                        policy expires, that share of surplus shall be considered as a donation by the enrollee 
                        to a takaful reserve fund to preserve the objective of providing health cover to current 
                        and future enrollees of Aman HMO.
                    </ul>
                    <ul>
                        <strong>9.</strong> No more than 50% of the underwriting surplus shall be available 
                        for distribution to enrollees in line with section 7 above. 
                        Undistributed surplus shall be deemed donated to the takaful reserve fund. 
                        Each year, claims by service providers and expenses on behalf of enrollees shall 
                        first be charged against the contribution pool (takaful fund) before applying to the 
                        takaful reserve fund.
                    </ul>
                    <ul>
                        <strong>10.</strong> In the event of the Association’s (Aman HMO) liquidation, 
                        takaful reserve fund residual shall be used for charity as decided by the Sharia 
                        Advisers after settlement of all the enrollees’ rights and claims from health 
                        service providers.
                    </ul>
                </p>
                <br></br>
                <h2>DATA CONSENT DECLARATION</h2>
                <p style={{paddingLeft: '.5em'}}>
                    By Clicking “Agree” I hereby confirm by myself/through my authorized representative that I 
                    have the legal capacity to give consent and hereby by myself/through my authorized 
                    representative voluntarily grant my consent to Aman HMO and its duly 
                    appointed representatives, the authority to access, retrieve, process, store, 
                    transfer as well as use for any legitimate and lawful purpose, my personal and/or 
                    medical information including all relevant data envisaged by the extant law 
                    including but not limited to the Nigerian Data Protection Regulation 
                    (and any amendment thereto) solely for the purpose of carrying out their dues and 
                    responsibilities as my mutual health association. 
                    <br></br><br></br>
                    I confirm that I am aware of my rights, 
                    liabilities, and method to withdraw my consent herein at any time by notifying Aman HMO 
                    in writing and accordingly request that this consent remain in full force and subsist 
                    until such a time as when I withdraw the same. 
                    <br></br><br></br>
                    In addition, I hereby grant the 
                    same consent to Aman HMO on behalf of all my dependant(s) who are 
                    minors and accordingly request that this consent remain in full force 
                    and subsist until such a time as when I withdraw same or such a time when my 
                    dependent each personally withdraws the same after attaining the statutory age of majority.
                </p>
            </div>
        </Modal >
    )
}