import React, { useState, useEffect } from 'react';
import { useNotifications } from '@mantine/notifications';

import {Modal, Button, Divider } from 'antd';
import { Oval } from "react-loader-spinner";
import helpers from '../../core/Helpers';
import { Select } from 'antd';
import { Form } from 'antd';
import { useAuth } from '../../core/hooks/useAuth';
import { NullSafe } from '../../core/Helpers'

import lib from '../lib';
import { InfoCircleOutlined } from '@ant-design/icons';


const EditBio = (props) => {
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [stateHealthCare, setStateHealtCare] = useState([]);
  const { Option } = Select;
  const notify = useNotifications();
  const [isBioVisible, setIsBioVisible] = useState(false);
  const { user } = useAuth();

  const [form] = Form.useForm();

  const showBioModal = () => {
    setIsBioVisible(true);
  }; 

  const handleBioCancel = () => {
    setIsBioVisible(false);
  };

  useEffect(() => {
    (async () => {
      let obj = {
        state: props.data?.state,
        plan_type: user?.insurance_package
      }
      let reqData = await lib.getProviders(obj);

      if (reqData.data?.error === 1) {
        helpers.alert({ notifications: notify, icon: 'error', color: 'red', message: reqData.data.message })
      }
      if (reqData?.error === 0) {
        setStateHealtCare(NullSafe(reqData.data))
      }
    })()
  },[props.data?.state, user?.insurance_package]) //eslint-disable-line


  const handleBioOk = async () => {
    values.insurance_no = props.data?.insurance_no

    setLoading(true);

    let obj = {
      data: values,
      ...values
    }
    let reqData = await lib.updateBio(obj)

    if (reqData.status === 'error') {
      helpers.alert({ notifications: notify, icon: 'error', color: 'red', message: reqData.msg })
    }

    if (reqData?.error === 1) {
      helpers.alert({ notifications: notify, icon: 'error', color: 'red', message: reqData.message })
    }
    if (reqData?.error === 0) {
      helpers.alert({ notifications: notify, icon: 'success', color: 'green', message: reqData.message })
      setIsBioVisible(false);
      setTimeout(()=> window.location.reload(), 500);
    }
    setLoading(false);
  };

  return (
    <>
    <Button style={{backgroundColor: "#09A479", border: "none"}}  type="primary" onClick={showBioModal}>Edit Bio</Button>
      <Modal title="Edit Healthcare Facility" visible={isBioVisible} onOk={handleBioOk} onCancel={handleBioCancel} style={{width: '80%'}}>
        <div className="profile-password" style={{width: '100%'}}>
          <div className='form-group'>
            <div className='form-group'>
              <Form
                form={form}
                layout="vertical"
                onFinish={null}
                onFinishFailed={null}
                autoComplete="off"
              >
                <Form.Item label="Primary Health Care Facility" tooltip="Enter health care facility">
                  <Select style={{ width: "98%", marginRight: "10px" }} onChange={e => setValues(d => ({ ...d, primary_health_facility: e }))} value={values?.primary_health_facility}>
                    {stateHealthCare?.map(item => (
                      <>
                        <Option value={item.facility_name} title={item.facility_name + ' - ' + item.facility_plan_type.toUpperCase()} style={{borderBottom: "1px solid #91caff"}}>
                          <span>
                            {item.facility_name} <Divider type='vertical' style={{margin: 5, borderColor: '#001d66'}}/> {item.facility_plan_type.toUpperCase()}
                          </span>
                          <Divider style={{margin: 0}}/>
                          <span>
                            Address: {item.facility_address}
                          </span>
                          <Divider style={{margin: 0}}/>
                          <span>
                            State: {item.facility_state} <Divider type='vertical' style={{margin: 5, borderColor: '#001d66'}}/> City: {item.facility_city.toUpperCase()}
                          </span>
                        </Option>
                      </>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Secondary Health Care Facility" tooltip={{ title: 'Enter health care facility', icon: <InfoCircleOutlined /> }} >
                  <Select style={{ width: "98%", marginRight: "10px" }} onChange={e => setValues(d => ({ ...d, secondary_health_facility: e }))} value={values?.secondary_health_facility}>
                    {stateHealthCare?.map(item => (
                      <>
                        <Option value={item.facility_name} title={item.facility_name + ' - ' + item.facility_plan_type.toUpperCase()} style={{borderBottom: "1px solid #91caff"}}>
                          <span>
                            {item.facility_name} <Divider type='vertical' style={{margin: 5, borderColor: '#001d66'}}/> {item.facility_plan_type.toUpperCase()}
                          </span>
                          <Divider style={{margin: 0}}/>
                          <span>
                            Address: {item.facility_address}
                          </span>
                          <Divider style={{margin: 0}}/>
                          <span>
                            State: {item.facility_state} <Divider type='vertical' style={{margin: 5, borderColor: '#001d66'}}/> City: {item.facility_city.toUpperCase()}
                          </span>
                        </Option>
                      </>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </div>

            <Oval
              color="#00BFFF"
              height={30}
              visible={loading}
              width={30}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}


export default EditBio;